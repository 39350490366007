import FullCalendar from "@fullcalendar/react";
import React, { useEffect, useState } from "react";
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import EditAppointmenModal from "./editAppointmenModal";

const CalendarView = ({
  isDarkMode,
  appointments,
  setAppointments,
  eventId,
  setIsLoading
}) => {
  const [appointmentData, seAppointmentData] = useState("");
  function handleEventClick(appoData) {
    const data = appointments.filter((data) => data.id == appoData.event.id);

    // Set the state first, then show the modal after it is set
    seAppointmentData(data[0]);

    var createModal = new window.bootstrap.Modal(
      document.getElementById("editAppointment")
    );
    createModal.show();
  }

  return (
    <div className={`eventCalender ${isDarkMode ? "dark-mode" : "light-mode"}`}>
      <EditAppointmenModal
        isDarkMode={isDarkMode}
        appointmentData={appointmentData}
        seAppointmentData={seAppointmentData}
        eventId={eventId}
        setIsLoading={setIsLoading}
        appointments={appointments}
        setAppointments={setAppointments}
      />
      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        themeSystem="bootstrap5"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        // eventClick={handleEventClick}
        selectable={true}
        events={
          appointments && appointments.length > 0
            ? appointments.map((appointment) => {
                const appointmentDate =
                  appointment.appointments[0]?.appointment_date;
                const appointmentTime =
                  appointment.appointments[0]?.appointment_time;

                return {
                  id: appointment.id,
                  title: `${appointment.appointments[0]?.first_name} ${appointment.appointments[0]?.last_name}`,
                  start: new Date(`${appointmentDate}T${appointmentTime}`), // Proper concatenation for date and time
                  date: appointmentDate,
                };
              })
            : [
                { title: "Event 1", date: "2024-10-01" },
                { title: "Event 2", date: "2024-10-02" },
              ]
        }
        eventMouseEnter={handleEventClick}
        initialView="dayGridMonth"
        weekends={true}
      />
    </div>
  );
};

export default CalendarView;
