import { useEffect, useState } from "react";
import AdminNav from "./AdminNav";
import Events from "./Events.jsx";
// import logo from "./assets/logo.png"
import ManageNotification from "./MangeNotification";
import CreateEvent from "./CreateEvent";
import EditEventForm from "./EditEventForm";
import { useLocation, useNavigate } from "react-router-dom";
import EditClientForm from "./EditClientForm";
import { toast } from "react-toastify";
import useDealerships from "./useDealerships";
import Users from "./Users";
import Signup from "./createUser";
import EditUser from "./EditUserForm";
import UserProfile from "./userProfile";
import Dealership from "./Dealership";
import Loader from "./loader.jsx";
import Footer from "./footer.js";
import {
  allTypes,
  externalUsers,
  internalUsers,
} from "./constants/userTypes.js";
import CreatePrivateEvent from "./createPrivateEvent.jsx";
import Forms from "./Forms.jsx";
import useAllUsers from "./useAllUsers.jsx";
import ClientDashboard from "./ClientDashboard.jsx";
import BillingReport from "./billingReports.jsx";
import EventReport from "./eventReport.jsx";
import SalesReport from "./salesReport.jsx";
import EvenView from "./components/evenView.jsx";
import Clients from "./components/clients.jsx";
import CreateProspective from "./components/createProspective.jsx";
import Appointments from "./pages/appointments/appointments.jsx";

const EventPortal = ({ setIsDarkMode, isDarkMode }) => {
  const [displayComponent, setDisplayComponent] = useState();
  // const [searchParams]=useSearchParams()
  const [page, setPage] = useState();
  const location = useLocation();
  const navigate = useNavigate();

  const [eventTrigger, setEventTrigger] = useState();
  // Check if the token exists in local storage
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [editEventID, setEditEventID] = useState(0); //To get event ID form events component, which event form have to be edited
  const [editClientID, setEditClientID] = useState(0); //To get Client ID from Edit Events component, which client form have to be edited
  const userType = localStorage.getItem("userType");

  const [prevDisplay, setPrevDisplay] = useState(displayComponent);
  //dealerships
  const [dealerTrigger, setDealerTrigger] = useState(false);
  const dealerships = useDealerships(token, dealerTrigger);
  const users = useAllUsers(token, dealerTrigger);

  // const allTypes = ["admin", "manager", "representative", "animator"];
  const [isLoading, setIsLoading] = useState(false);
  // useEffect(() => {
  // const elementsToStyleLight = document.querySelectorAll(
  //   'h1, h2, h3, h4, h5, h6, p, ul, li, span, table, td, tr, thead,div, th, body, nav, .navbar, label'
  // );

  // const elementsToStyleDark = document.querySelectorAll(
  //   '.App, .container, .event-portal, .dark-light, .footertext, .modal-content div'
  // );

  // if (isDarkMode) {
  //   document.documentElement.style.setProperty('--color-background', '#1f2023');
  //   document.documentElement.style.setProperty('--color-text', '#fff');
  //   document.documentElement.style.setProperty('--color-background-second', '#000');
  // } else {
  //   document.documentElement.style.setProperty('--color-background', '#fff');
  //   document.documentElement.style.setProperty('--color-text', '#000');
  //   document.documentElement.style.setProperty('--color-background-second', '#F5F5FB');
  // }

  // Apply styles for text color and background color in light mode
  // elementsToStyleLight.forEach((element) => {
  //   if (isDarkMode) {
  //     element.classList.toggle('dark-mode-text', isDarkMode);
  //     // Apply the light mode class only if dark mode is not active
  //     element.classList.toggle('dark-mode-bg-light', isDarkMode);
  //   } else {
  //     element.classList.remove('dark-mode-bg-light');
  //     element.classList.remove('dark-mode-text');
  //   }
  // });
  // // Apply styles for text color and background color in dark mode
  // elementsToStyleDark.forEach((element) => {
  //   if (isDarkMode) {
  //     element.classList.toggle('dark-mode-text', isDarkMode);
  //     // Remove the light mode class if dark mode is active
  //     element.classList.remove('dark-mode-bg-light');
  //     element.classList.toggle('dark-mode-bg-dark', isDarkMode);
  //   } else {
  //     element.classList.remove('dark-mode-text');
  //     element.classList.remove('dark-mode-bg-dark');
  //   }
  // });

  // }, [isDarkMode, displayComponent]);
  // // Use state to store the dealerships
  // const [dealerships, setDealerships] = useState(dealershipsHook);

  useEffect(() => {
    // If the token exists, redirect to "/event-portal"
    if (!token) {
      navigate("/");
    } else {
      // console.log('usertype eventportal', userType)
      if (externalUsers.includes(userType)) {
        // navigate("/client-form", { replace: true });
        navigate("/event-portal", { replace: true });
      } else if (internalUsers.includes(userType)) {
        // navigate("/event-portal", { replace: true });
      } else {
        toast.error("Something went wrong!");
        navigate("/", { replace: true });
      }
    }
  }, [token, userType]);

  // Get the current URL
  var currentUrl = window.location.href;
  // Find the index of '=' in the URL
  var equalsIndex = currentUrl.indexOf("=");
  // Check if '=' is found and extract the text after it
  useEffect(() => {
    currentUrl = window.location.href;
    if (equalsIndex !== -1) {
      const questionMarkIndex = currentUrl.indexOf("&", equalsIndex);
      const endIndex = questionMarkIndex !== -1 ? questionMarkIndex : undefined;
      setDisplayComponent(currentUrl.slice(equalsIndex + 1, endIndex));
      // console.log("Text after '=':", displayComponent);
    } else {
      // console.log("No '=' found in the URL");
      setDisplayComponent("events");
    }
  });

  // const queryParams = new URLSearchParams(location.search);
  // setPage(queryParams.get('page'));
  // console.log("===========> ", displayComponent)
  // setPage(textAfterEquals)
  window.onpopstate = function (e) {
    // console.log(e);
    if (e.state) {
      currentUrl = window.location.href;
      if (equalsIndex !== -1) {
        setDisplayComponent(currentUrl.slice(equalsIndex + 1));
        // console.log("-----------------------------------Text after '=':", displayComponent);
      } else {
        // console.log("------------------------------------No '=' found in the URL");
        setDisplayComponent("events");
      }
      // setDisplayComponent((prev) => {
      //   if (prev === 'editclient') return 'editevent'
      //   else if (prev === 'editevent') return 'events'
      //   else return prevDisplay
      // })
    }
  };

  const Tabs = [
    {
      title: "events",
      userTypes: allTypes,
      component: (
        <Events
          setDisplayComponent={setDisplayComponent}
          eventTrigger={eventTrigger}
          setEventTrigger={setEventTrigger}
          setEditEventID={setEditEventID}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          users={users}
          dealerships={dealerships}
          setPrevDisplay={setPrevDisplay}
        />
      ),
    },
    {
      title: "forms",
      userTypes: allTypes,
      component: (
        <Forms
          setDisplayComponent={setDisplayComponent}
          eventTrigger={eventTrigger}
          setEventTrigger={setEventTrigger}
          setEditEventID={setEditEventID}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
        />
      ),
    },
    {
      title: "user-profile",
      userTypes: allTypes,
      component: (
        <UserProfile setIsLoading={setIsLoading} isDarkMode={isDarkMode} />
      ),
    },
    {
      title: "create-event",
      userTypes: allTypes,
      component: (
        <CreateEvent
          setDisplayComponent={setDisplayComponent}
          dealerships={dealerships}
          setDealerTrigger={setDealerTrigger}
          setIsLoading={setIsLoading}
          isDarkMode={isDarkMode}
          users={users}
        />
      ),
    },
    {
      title: "create-privateEvent",
      userTypes: allTypes,
      component: (
        <CreatePrivateEvent
          setDisplayComponent={setDisplayComponent}
          dealerships={dealerships}
          setDealerTrigger={setDealerTrigger}
          setIsLoading={setIsLoading}
          isDarkMode={isDarkMode}
          users={users}
        />
      ),
    },
    {
      title: "dealership",
      userTypes: allTypes,
      component: (
        <Dealership
          setDealerTrigger={setDealerTrigger}
          setIsLoading={setIsLoading}
          isDarkMode={isDarkMode}
          users={users}
        />
      ),
    },
    {
      title: "notifications",
      userTypes: allTypes,
      component: <ManageNotification setIsLoading={setIsLoading} />,
    },
    {
      title: "edit-event",
      userTypes: allTypes,
      component: (
        <CreatePrivateEvent
          setDisplayComponent={setDisplayComponent}
          dealerships={dealerships}
          setDealerTrigger={setDealerTrigger}
          setIsLoading={setIsLoading}
          isDarkMode={isDarkMode}
          editMode={true}
          users={users}
        />
      ),
    },
    {
      title: "edit-form",
      userTypes: allTypes,
      component: (
        <EditEventForm
          isDarkMode={isDarkMode}
          setDisplayComponent={setDisplayComponent}
          setEditClientID={setEditClientID}
          users={users}
        />
      ),
    },
    {
      title: "create-user",
      userTypes: allTypes,
      component: (
        <Signup
          setDisplayComponent={setDisplayComponent}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          isDarkMode={isDarkMode}
        />
      ),
    },
    {
      title: "edit-user",
      userTypes: allTypes,
      component: (
        <EditUser
          setDisplayComponent={setDisplayComponent}
          setIsLoading={setIsLoading}
          isDarkMode={isDarkMode}
        />
      ),
    },
    {
      title: "edit-client",
      userTypes: allTypes,
      component: (
        <EditClientForm
          setDisplayComponent={setDisplayComponent}
          editClientID={editClientID}
          editEventID={editEventID}
          setIsLoading={setIsLoading}
          isDarkMode={isDarkMode}
        />
      ),
    },
    {
      title: "manage-users",
      userTypes: allTypes,
      component: (
        <Users
          setDisplayComponent={setDisplayComponent}
          setIsLoading={setIsLoading}
          isDarkMode={isDarkMode}
        />
      ),
    },
    {
      title: "view-submissons",
      userTypes: allTypes,
      component: (
        <ClientDashboard
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
        />
      ),
    },
    {
      title: "view-billing-report",
      userTypes: allTypes,
      component: (
        <BillingReport
          setDisplayComponent={setDisplayComponent}
          eventTrigger={eventTrigger}
          setEventTrigger={setEventTrigger}
          setEditEventID={setEditEventID}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          users={users}
          dealerships={dealerships}
          setPrevDisplay={setPrevDisplay}
        />
      ),
    },
    {
      title: "view-event-report",
      userTypes: allTypes,
      component: (
        <EventReport
          setDisplayComponent={setDisplayComponent}
          eventTrigger={eventTrigger}
          setEventTrigger={setEventTrigger}
          setEditEventID={setEditEventID}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          users={users}
          dealerships={dealerships}
          setPrevDisplay={setPrevDisplay}
        />
      ),
    },
    {
      title: "view-sales-report",
      userTypes: allTypes,
      component: (
        <SalesReport
          setDisplayComponent={setDisplayComponent}
          eventTrigger={eventTrigger}
          setEventTrigger={setEventTrigger}
          setEditEventID={setEditEventID}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          users={users}
          dealerships={dealerships}
        />
      ),
    },
    {
      title: "event-view",
      userTypes: allTypes,
      component: (
        <EvenView
          isDarkMode={isDarkMode}
          setDisplayComponent={setDisplayComponent}
          setPrevDisplay={setPrevDisplay}
        />
      ),
    },
    {
      title: "clients",
      userTypes: allTypes,
      component: (
        <Clients
          isDarkMode={isDarkMode}
          setDisplayComponent={setDisplayComponent}
          setPrevDisplay={setPrevDisplay}
        />
      ),
    },
    {
      title: "create-prospective",
      userTypes: allTypes,
      component: (
        <CreateProspective
          isDarkMode={isDarkMode}
          setDisplayComponent={setDisplayComponent}
          setPrevDisplay={setPrevDisplay}
          setIsLoading={setIsLoading}
          users={users}
        />
      ),
    },
    {
      title: "edit-prospective",
      userTypes: allTypes,
      component: (
        <CreateProspective
          isDarkMode={isDarkMode}
          setDisplayComponent={setDisplayComponent}
          setPrevDisplay={setPrevDisplay}
          editMode={true}
          setIsLoading={setIsLoading}
          users={users}
        />
      ),
    },
    {
      title: "appointments",
      userTypes: allTypes,
      component: (
        <Appointments
          isDarkMode={isDarkMode}
          setDisplayComponent={setDisplayComponent}
          setPrevDisplay={setPrevDisplay}
          setIsLoading={setIsLoading}
          users={users}
        />
      ),
    },
    {
      title: "edit-appointment",
      userTypes: allTypes,
      component: (
        <CreateProspective
          isDarkMode={isDarkMode}
          setDisplayComponent={setDisplayComponent}
          setPrevDisplay={setPrevDisplay}
          editMode={true}
          setIsLoading={setIsLoading}
          users={users}
        />
      ),
    },
  ];

  const getCurrentTab = () => {
    const activeComponent = Tabs.find(
      (tab) =>
        tab.title === displayComponent && tab.userTypes.includes(userType)
    )?.component;
    return (
      activeComponent || (
        <div className="appcountainer2 d-flex justify-content-center">
          <h3 className="py-4">Not Found</h3>{" "}
        </div>
      )
    );
  };

  return (
    <div>
      {isLoading && (
        <div className="splash-screen">
          <Loader />
        </div>
      )}
      <div className="event-portal">
        <AdminNav
          setDisplayComponent={setDisplayComponent}
          setEventTrigger={setEventTrigger}
          dealerships={dealerships}
          setDealerTrigger={setDealerTrigger}
          setToken={setToken}
          token={token}
          setIsDarkMode={setIsDarkMode}
          isDarkMode={isDarkMode}
          setPrevDisplay={setPrevDisplay}
          setIsLoading={setIsLoading}
        />
        {displayComponent && (
          <div
            className={`${
              getCurrentTab().type.name === "Signup" ||
              getCurrentTab().type.name === "EditUser" ||
              getCurrentTab().type.name === "ip" ||
              getCurrentTab().type.name === "op"
                ? ""
                : ""
            }`} /*style={{margin: "0px 15px !important", background: "#fff"}}*/
          >
            {/* {console.log(getCurrentTab())} */}
            {getCurrentTab()}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventPortal;
