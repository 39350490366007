import React from "react";
import { useLanguage } from "../../../languageContext";
import translations from "../../../translation.json";
import { ApiUrl } from "../../../ApiUrl";
import { toast } from "react-toastify";
const TableView = ({
  isDarkMode,
  appointments,
  setDisplayComponent,
  setDeleteStates,
  eventId,
  deleteStates,
  setLoading,
  setAppointments,
}) => {
  const { language } = useLanguage();
  const token = localStorage.getItem("token");

  const handleDelete = async (id, index) => {
    setLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/prospectives/" + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        // toast.success("Dealership deleted successful");
        const newData = appointments.filter((client) => client.id !== id);
        setAppointments(newData);
        // Update delete state for the current item
        const newDeleteStates = [...deleteStates];
        newDeleteStates[index] = false;
        setDeleteStates(newDeleteStates);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };
  return (
    <div
      // className="pt-4"
      // style={{
      //   display: "flex",
      //   flexDirection: "column",
      //   justifyContent: "space-between",
      // }}
      // ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        overflowX: "auto",
        justifyContent: "space-between",
        height: "calc(100vh - 320px)",
      }}
    >
      <table className="table table-hover text-center align-middle">
        <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
          <tr>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "125px" : "150px",
              }}
            >
              {translations.clients.table.prospectiveType[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "140px" : "165px",
              }}
            >
              {translations.createProspective.prospectiveSource[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "100px" : "100px",
              }}
            >
              {translations.commonFields.firstName[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "100px" : "135px",
              }}
            >
              {translations.commonFields.lastName[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "130px" : "180px",
              }}
            >
              {translations.quickCreationModal.phoneNumber[language]}
            </th>

            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "100px" : "100px",
              }}
            >
              {translations.createProspective.make[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "100px" : "100px",
              }}
            >
              {translations.createProspective.model[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{ minWidth: language === "en" ? "95px" : "90px" }}
            >
              {translations.usersPage.createUser.year[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "160px" : "155px",
              }}
            >
              {translations.clients.table.appointmentDate[language]}
            </th>

            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "160px" : "155px",
              }}
            >
              {translations.clients.table.appointmentTime[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "195px" : "130px",
              }}
            >
              {translations.createProspective.appointmentComment[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "170px" : "195px",
              }}
            >
              {translations.createProspective.confirmationStatus[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "165px" : "195px",
              }}
            >
              {translations.createProspective.confirmationNotes[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "170px" : "195px",
              }}
            >
              {translations.clients.table.appointmentStatus[language]}
            </th>
            <th
              scope="col"
              className={`${isDarkMode ? "darkthead" : ""}`}
              style={{
                minWidth: language === "en" ? "220px" : "195px",
              }}
            >
              {translations.createProspective.appointmentStatusNotes[language]}
            </th>
            <th
              scope="col"
              colSpan={2}
              className={`${isDarkMode ? "darkthead" : ""}`}
            >
              Actions
            </th>
            {/* <th scope="col"></th> */}
          </tr>
        </thead>
        <tbody id="">
          {appointments && appointments.length > 0 ? (
            appointments.map((appointment, index) => {
              // console.log(event.number);
              return (
                <>
                  <tr className="result-box" key={appointment.id}>
                    <td className="dlrname">
                      {appointment.appointments[0]?.prospect_type}
                    </td>
                    <td className="dlrname">{appointment?.prospect_source}</td>
                    <td className="dlrname">
                      {appointment.appointments[0]?.first_name}
                    </td>
                    <td className="dlrname">
                      {appointment.appointments[0]?.last_name}
                    </td>
                    <td className="salesrep">
                      {appointment.appointments[0]?.phone}
                    </td>
                    <td className="salesrep">
                      {appointment.appointments[0]?.make}
                    </td>
                    <td className="salesrep">
                      {appointment.appointments[0]?.model}
                    </td>
                    <td className="salesrep">
                      {appointment.appointments[0]?.year}
                    </td>
                    <td className="salesrep">
                      {appointment.appointments[0]?.appointment_date}
                    </td>
                    <td className="salesrep">
                      {appointment.appointments[0]?.appointment_time}
                    </td>
                    <td className="salesrep">
                      {appointment.appointments[0]?.appointment_comment}
                    </td>
                    <td className="salesrep">{appointment.appointments[0]?.confirmation_status}</td>

                    <td className="salesrep">{appointment.appointments[0]?.confirmation_notes}</td>
                    <td className="salesrep">{appointment.appointments[0]?.appointment_status}</td>
                    <td className="salesrep">{appointment.appointments[0]?.appointment_status_notes}</td>
                    <td style={{}}>
                      <button
                        type="button"
                        className="btn btn-light edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                        //   data-customer-id="405"
                        //   data-bs-toggle="modal"
                        //   data-bs-target="#AddDealerModal"
                        onClick={() => {
                          setDisplayComponent("edit-appointment");
                          //   setEditEventID(event.id);
                          window.history.pushState(
                            {
                              id: "gallery",
                              randomData: window.Math.random(),
                            },
                            "title",
                            "/event-portal?page=edit-appointment&event_id=" +
                              eventId +
                              `&client_id=${appointment.id}`
                          );
                        }}
                      >
                        <i className="bi bi-eye"></i>{" "}
                        {translations.commonWords.view[language]}
                      </button>
                    </td>

                    <td style={{ width: "170px" }}>
                      <div className="btn-group" role="group">
                        {deleteStates[index] ? (
                          <>
                            <button
                              type="button"
                              className="btn btn-success delete-button"
                              data-customer-id="405"
                              onClick={() =>
                                handleDelete(appointment.id, index)
                              }
                            >
                              {translations.commonFields.confirm[language]}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary cancel-button"
                              onClick={() =>
                                setDeleteStates((prevStates) => {
                                  const newStates = [...prevStates];
                                  newStates[index] = false;
                                  return newStates;
                                })
                              }
                            >
                              {translations.commonFields.cancel[language]}
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            className="btn btn-danger before-delete-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                            dataset-button-state="delete"
                            onClick={() =>
                              setDeleteStates((prevStates) => {
                                const newStates = [...prevStates];
                                newStates[index] = true;
                                return newStates;
                              })
                            }
                          >
                            <i className="bi bi-trash"></i>{" "}
                            {translations.commonFields.delete[language]}
                          </button>
                        )}
                      </div>
                    </td>
                  </tr>
                </>
              );
            })
          ) : (
            <tr
              className="align-middle"
              style={{
                position: "relative",
                // height: "30px",
                // zIndex: 10 + eventList.length - index,
              }}
            >
              <td
                style={{
                  background: `${isDarkMode ? "transparent" : "white"}`,
                  borderStyle: "none",
                }}
                colSpan={16}
                className="text-center py-5"
              >
                <h4>{translations.EventsPortal.noDataMessage[language]}</h4>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      {/* <div ref={bottomMarkerRef} style={{ height: "1px" }} /> */}
    </div>
  );
};

export default TableView;
