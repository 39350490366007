import React, { useEffect, useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import BulkUpload from "./bulkuploadModal";
import { ApiUrl } from "../ApiUrl";
import { toast } from "react-toastify";
import Loader from "../loader";
const Clients = ({ isDarkMode, setDisplayComponent, setPrevDisplay }) => {
  const { language } = useLanguage();
  const [deleteStates, setDeleteStates] = useState();
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventId, setEventId] = useState(null);
  const token = localStorage.getItem("token");
  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetch(ApiUrl + "/api/user/prospectives/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          // const newData = data.private_events.data;

          setClients(data.prospectives);

          setDeleteStates(data.prospectives.map(() => false));

          // autoScrollMid();
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
    };
    if (token) {
      fetchData();
    }
  }, []);

  const handleDelete = async (id, index) => {
    setLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/prospectives/" + id, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        // toast.success("Dealership deleted successful");
        const newData = clients.filter((client) => client.id !== id);
        setClients(newData);
        // Update delete state for the current item
        const newDeleteStates = [...deleteStates];
        newDeleteStates[index] = false;
        setDeleteStates(newDeleteStates);
      } else {
        toast.error(data.message);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div>
      {!loading ? (
        <div className="container maincont">
          <div id="app" className="appcountainer2">
            <BulkUpload isDarkMode={isDarkMode} eventId={eventId} />
            <div className="row align-items-center justify-content-between pb-4">
              <div className="col-4 d-flex gap-3 align-items-center">
                <div>
                  <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                    {translations.clients.title[language]}
                  </h2>
                </div>
              </div>
              <div className="col-8 gap-1 d-flex align-items-center justify-content-end">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("events");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=events"
                      );
                    }}
                  >
                    <i className="bi bi-speedometer"></i>{" "}
                    {translations.eventView.buttons.dashboard[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    onClick={() => {
                      setDisplayComponent("appointments");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=appointments&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-calendar2-range"></i>{" "}
                    {translations.eventView.buttons.calender[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className=""
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    style={{
                      background: "#05cc9e",
                      color: "white",
                      paddingBlock: "0.375rem",
                      paddingInline: "0.75rem",
                      borderRadius: "0.375rem",
                      borderColor: "#05cc9e",
                      borderStyle: "solid",
                    }}
                  >
                    <i className="bi bi-people"></i>{" "}
                    {translations.forms.formFields.clients[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    onClick={() => {
                      setDisplayComponent("create-prospective");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=create-prospective&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className={`bi bi-clipboard-plus`}></i>{" "}
                    {translations.commonFields.addBtn[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#bulkUploadModal"
                    id="add-dlr-trigger"
                  >
                    <i className="bi bi-upload"></i>{" "}
                    {translations.commonFields.bulkUpload[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                  >
                    <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                    {translations.viewEventReport.title[language]}
                  </button>
                </div>
              </div>
            </div>
            <div
              // className="pt-4"
              // style={{
              //   display: "flex",
              //   flexDirection: "column",
              //   justifyContent: "space-between",
              // }}
              // ref={containerRef}
              style={{
                display: "flex",
                flexDirection: "column",
                overflowX: "auto",
                justifyContent: "space-between",
                height: "calc(100vh - 260px)",
              }}
            >
              <table className="table table-hover text-center align-middle">
                <thead style={{ position: "sticky", top: "0", zIndex: "1" }}>
                  <tr>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "125px" : "150px",
                      }}
                    >
                      {translations.clients.table.prospectiveType[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "140px" : "165px",
                      }}
                    >
                      {
                        translations.createProspective.prospectiveSource[
                          language
                        ]
                      }
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "100px",
                      }}
                    >
                      {translations.commonFields.firstName[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "135px",
                      }}
                    >
                      {translations.commonFields.lastName[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "130px" : "180px",
                      }}
                    >
                      {translations.quickCreationModal.phoneNumber[language]}
                    </th>

                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "130px" : "155px",
                      }}
                    >
                      {translations.createProspective.officeNumber[language]}
                    </th>

                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                    >
                      {
                        translations.commonWords.usersTypes.representative[
                          language
                        ]
                      }
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "130px",
                      }}
                    >
                      {translations.clients.table.callStatus[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "100px",
                      }}
                    >
                      {translations.createProspective.make[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "100px" : "100px",
                      }}
                    >
                      {translations.createProspective.model[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{ minWidth: language === "en" ? "95px" : "90px" }}
                    >
                      {translations.usersPage.createUser.year[language]}
                    </th>
                    <th
                      scope="col"
                      className={`${isDarkMode ? "darkthead" : ""}`}
                      style={{
                        minWidth: language === "en" ? "135px" : "195px",
                      }}
                    >
                      {translations.clients.table.noShowStatus[language]}
                    </th>
                    <th
                      scope="col"
                      colSpan={2}
                      className={`${isDarkMode ? "darkthead" : ""}`}
                    >
                      Actions
                    </th>
                    {/* <th scope="col"></th> */}
                  </tr>
                </thead>
                <tbody id="">
                  {clients && clients.length > 0 ? (
                    clients.map((client, index) => {
                      
                      return (
                        <>
                          <tr className="result-box" key={client.id}>
                            <th className="custID" scope="row">
                              {client.id}
                            </th>
                            <td className="dlrname">
                              {client.appointments[0]?.prospect_type}
                            </td>
                            <td className="dlrname">
                              {client?.prospect_source}
                            </td>
                            <td className="dlrname">
                              {client.appointments[0]?.first_name}
                            </td>
                            <td className="dlrname">
                              {client.appointments[0]?.last_name}
                            </td>
                            <td className="salesrep">
                              {client.appointments[0]?.phone}
                            </td>
                            <td className="salesrep">{client?.office_phone}</td>

                            <td className="salesrep">
                              {client?.representative}
                            </td>
                            <td className="salesrep">{client?.call_status}</td>
                            <td className="salesrep">
                              {client.appointments[0]?.make}
                            </td>
                            <td className="salesrep">
                              {client.appointments[0]?.model}
                            </td>
                            <td className="salesrep">
                              {client.appointments[0]?.year}
                            </td>
                            <td className="salesrep">
                              {client?.no_show_status}
                            </td>
                            <td style={{}}>
                              <button
                                type="button"
                                className="btn btn-light edit-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                //   data-customer-id="405"
                                //   data-bs-toggle="modal"
                                //   data-bs-target="#AddDealerModal"
                                onClick={() => {
                                  setDisplayComponent("edit-event");
                                  //   setEditEventID(event.id);
                                  window.history.pushState(
                                    {
                                      id: "gallery",
                                      randomData: window.Math.random(),
                                    },
                                    "title",
                                    "/event-portal?page=edit-prospective&event_id=" +
                                      eventId +
                                      `&client_id=${client.id}`
                                  );
                                }}
                              >
                                <i className="bi bi-eye"></i>{" "}
                                {translations.commonWords.view[language]}
                              </button>
                            </td>

                            <td style={{ width: "170px" }}>
                              <div className="btn-group" role="group">
                                {deleteStates[index] ? (
                                  <>
                                    <button
                                      type="button"
                                      className="btn btn-success delete-button"
                                      data-customer-id="405"
                                      onClick={() =>
                                        handleDelete(client.id, index)
                                      }
                                    >
                                      {
                                        translations.commonFields.confirm[
                                          language
                                        ]
                                      }
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-secondary cancel-button"
                                      onClick={() =>
                                        setDeleteStates((prevStates) => {
                                          const newStates = [...prevStates];
                                          newStates[index] = false;
                                          return newStates;
                                        })
                                      }
                                    >
                                      {
                                        translations.commonFields.cancel[
                                          language
                                        ]
                                      }
                                    </button>
                                  </>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-danger before-delete-button d-flex align-items-center justify-content-center gap-1 mx-auto"
                                    dataset-button-state="delete"
                                    onClick={() =>
                                      setDeleteStates((prevStates) => {
                                        const newStates = [...prevStates];
                                        newStates[index] = true;
                                        return newStates;
                                      })
                                    }
                                  >
                                    <i className="bi bi-trash"></i>{" "}
                                    {translations.commonFields.delete[language]}
                                  </button>
                                )}
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })
                  ) : (
                    <tr
                      className="align-middle"
                      style={{
                        position: "relative",
                        // height: "30px",
                        // zIndex: 10 + eventList.length - index,
                      }}
                    >
                      <td
                        style={{
                          background: `${isDarkMode ? "transparent" : "white"}`,
                          borderStyle: "none",
                        }}
                        colSpan={14}
                        className="text-center py-5"
                      >
                        <h4>
                          {translations.EventsPortal.noDataMessage[language]}
                        </h4>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {/* <div ref={bottomMarkerRef} style={{ height: "1px" }} /> */}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Clients;
