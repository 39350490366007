import React, { useEffect, useState } from "react";
import { useLanguage } from "../../languageContext";
import translations from "../../translation.json";
import { useNavigate } from "react-router-dom";
import { ApiUrl } from "../../ApiUrl";
import { toast } from "react-toastify";
import TableView from "./components/tableView";
import CalendarView from "./components/calendarView";
import Loader from "../../loader";

const Appointments = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  setIsLoading,
}) => {
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [showTable, setShowTable] = useState(true);
  const [deleteStates, setDeleteStates] = useState();
  const [appointments, setAppointments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventId, setEventId] = useState(null);
  const token = localStorage.getItem("token");
  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await fetch(ApiUrl + "/api/user/prospectives/", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await res.json();
        if (data.status === "success") {
          // const newData = data.private_events.data;

          setAppointments(data.prospectives);

          setDeleteStates(data.prospectives.map(() => false));

          // autoScrollMid();
        } else {
          toast.error(data.message);
        }
        setLoading(false);
      } catch (err) {
        setLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
    };
    if (token) {
      fetchData();
    }
  }, []);
  return (
    <div>
      {!loading ? (
        <div className="container maincont">
          <div id="app" className="appcountainer2">
            <div className="row align-items-center justify-content-between pb-4">
              <div className="col-4 d-flex gap-3 align-items-center">
                <div>
                  <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                    {translations.appointments.title[language]}
                  </h2>
                </div>
              </div>
              <div className="col-8 gap-1 d-flex align-items-center justify-content-end">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    onClick={() => {
                      setDisplayComponent("events");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        "/event-portal?page=events"
                      );
                    }}
                  >
                    <i className="bi bi-speedometer"></i>{" "}
                    {translations.eventView.buttons.dashboard[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    style={{
                      background: "#05cc9e",
                      color: "white",
                      paddingBlock: "0.375rem",
                      paddingInline: "0.75rem",
                      borderRadius: "0.375rem",
                      borderColor: "#05cc9e",
                      borderStyle: "solid",
                    }}
                  >
                    <i className="bi bi-calendar2-range"></i>{" "}
                    {translations.eventView.buttons.calender[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    onClick={() => {
                      setDisplayComponent("clients");

                      window.history.pushState(
                        {
                          id: "gallery",
                          randomData: window.Math.random(),
                        },
                        "title",
                        `/event-portal?page=clients&eventId=${eventId}`
                      );
                    }}
                  >
                    <i className="bi bi-people"></i>{" "}
                    {translations.forms.formFields.clients[language]}
                  </button>
                </div>

                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                  >
                    <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                    {translations.viewEventReport.title[language]}
                  </button>
                </div>
              </div>
            </div>
            <div className="row align-items-center justify-content-between pb-4">
              <div className="col-4 d-flex gap-3 align-items-center"></div>
              <div className="col-8 gap-1 d-flex align-items-center justify-content-end">
                <div className="d-flex gap-2 align-items-center">
                  <p
                    style={{
                      fontWeight: showTable ? "" : "bold",
                      color: showTable ? "" : "#05cc9e",
                      fontSize: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowTable(!showTable)}
                  >
                    Cal |{" "}
                  </p>
                  <p
                    style={{
                      fontWeight: showTable ? "bold" : "",
                      color: showTable ? "#05cc9e" : "",
                      fontSize: "24px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowTable(!showTable)}
                  >
                    List
                  </p>
                </div>
              </div>
            </div>

            {showTable ? (
              <TableView
                isDarkMode={isDarkMode}
                appointments={appointments}
                setDisplayComponent={setDisplayComponent}
                setDeleteStates={setDeleteStates}
                eventId={eventId}
                deleteStates={deleteStates}
                setLoading={setLoading}
                setAppointments={setAppointments}
              />
            ) : (
              <CalendarView
                isDarkMode={isDarkMode}
                appointments={appointments}
                setAppointments={setAppointments}
                eventId={eventId}
                setIsLoading={setIsLoading}
              />
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
};

export default Appointments;
