import { useEffect, useState } from "react";
import logo from "./assets/Nexus_logo@3x.png";
import logoDark from "./assets/Nexus_logo@3x.png";
// import logo from "./assets/logo.png";
import people from "./assets/adminnav-people.png";
import ModalAdminQuickAdd from "./ModalAdminQuickAdd";
import ChangePasswordModal from "./ChangePasswordModal";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import DarkModeToggle from "react-dark-mode-toggle";
import { useLanguage } from "./languageContext";
import LanguageToggle from "./languageToggle";
import translations from "./translation.json";
import { externalUsers } from "./constants/userTypes";

const AdminNav = ({
  setDisplayComponent,
  dealerships,
  setDealerTrigger,
  setEventTrigger,
  setToken,
  token,
  setIsDarkMode,
  isDarkMode,
  setPrevDisplay,
  setIsLoading,
}) => {
  const userType = localStorage.getItem("userType");
  const userData = JSON.parse(localStorage.getItem("userData"));
  const { language } = useLanguage();
  const currentYear = new Date().getFullYear();
  const pageTranslations = translations["topAndsidebar"];
  const handleLogout = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/logout", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();
      if (data.status === "success") {
        localStorage.removeItem("token");
        setToken(null);
        localStorage.removeItem("userType");
        localStorage.removeItem("userData");
        setIsDarkMode(false);
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      toast.error("Something went wrong!");
      localStorage.removeItem("token");
      setToken(null);
      setIsDarkMode(false);
      localStorage.removeItem("userType");
      localStorage.removeItem("userData");
    }
  };

  return (
    <div
      className="admin-nav"
      style={{ backgroundColor: isDarkMode ? "#2b3035" : "" }}
    >
      <nav
        id="mainav"
        className="navbar navbar-light bg-light sticky-top"
        style={{ marginBottom: 10, padding: "4px 0" }}
      >
        <div className="container-fluid">
          <div
            className="navbar-brand"
            onClick={() => {
              setDisplayComponent("events");
              window.history.pushState(
                { id: "gallery", randomData: window.Math.random() },
                "title",
                "/event-portal?page=events"
              );
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              id="search-link-logo"
              className="p-1"
              src={isDarkMode ? logoDark : logo}
              alt="Logo"
              height="38"
            />{" "}
            {/* | Nexus <span style={{ fontSize: 15 }}>V6</span> */}
          </div>

          <div className="d-flex gap-2 align-items-center  ">
            <DarkModeToggle
              onChange={setIsDarkMode}
              checked={isDarkMode}
              size={70}
              className="mx-2"
            />
            {/* <LanguageToggle /> */}
            <div className="btn-group" role="group" aria-label="Basic example">
              <button
                className="btn admin-btn btn-primary d-flex align-items-center "
                onClick={() => {
                  setDisplayComponent((prev) => {
                    setPrevDisplay(prev);
                    return "user-profile";
                  });
                  window.history.pushState(
                    { id: "userProfile", randomData: window.Math.random() },
                    "title",
                    "?page=user-profile"
                  );
                }}
              >
                <i className="bi bi-person-circle me-2"></i>
                {translations.topAndsidebar.profile[language]}
              </button>
              <button
                style={{ marginBottom: 0 }}
                className="btn btn-light bi bi-list navbar-toggler"
                data-bs-target="#offcanvasmain"
                data-bs-toggle="offcanvas"
                aria-bs-controls="offcanvasmain"
                type="button"
              ></button>
            </div>
          </div>
        </div>
      </nav>
      <div
        style={{
          overflow: "hidden",
          paddingTop: 0,
          bottom: "auto",
        }}
        className={`offcanvas offcanvas-end offcanvas-sm h-100 ${
          isDarkMode ? "bg-dark" : "bg-body-tertiary"
        } `}
        tabindex="-1"
        id="offcanvasmain"
        data-bs-backdrop="true"
        aria-bs-labelledby="offcanvasmainLabel"
      >
        {/* <div className="offcanvas-header" style={{ paddingBottom: 0 }}>
          <a className="navbar-brand text-center" id="offcanvasExampleLabel">
            <img className="logo" src={logo} alt="Logo" height="35" />
          </a>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          ></button>
        </div> */}
        {/* <hr style={{ marginTop: 0 }} /> */}
        <div className="offcanvas-body p-4 flex-column">
          <div className="d-flex justify-content-between align-items-center ">
            <a className="navbar-brand text-center" id="offcanvasExampleLabel">
              <img
                id="search-link-logo"
                className=" p-1 w-100"
                src={isDarkMode ? logoDark : logo}
                alt="Logo"
                height="38"
              />
            </a>
            <button
              type="button"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              style={{
                background: "transparent",
                color: isDarkMode ? "white" : "black",
                border: "none",
                fontWeight: "400",
                fontSize: "18px",
              }}
            >
              X
            </button>
          </div>
          <ul className="nav flex-column mt-4 gap-2 ">
            <li className="">
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className=" admin-btn nav-link d-flex align-items-center gap-1"
                  id="search-link"
                  fdprocessedid="dyv9n"
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    setDisplayComponent((prev) => {
                      setPrevDisplay(prev);
                      return "events";
                    });
                    window.history.pushState(
                      { id: "gallery", randomData: window.Math.random() },
                      "title",
                      "?page=events"
                    );
                  }}
                  // style={{backgroundColor:"none"}}
                >
                  <i className="bi bi-search"></i>{" "}
                  {pageTranslations.events[language]}
                </button>
              </div>
            </li>

            {/* <li className="">
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className=" admin-btn nav-link d-flex align-items-center gap-1"
                  id="search-link"
                  fdprocessedid="dyv9n"
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    setDisplayComponent((prev) => {
                      setPrevDisplay(prev);
                      return "forms";
                    });
                    window.history.pushState(
                      { id: "gallery", randomData: window.Math.random() },
                      "title",
                      "?page=forms"
                    );
                  }}
                  // style={{backgroundColor:"none"}}
                >
                  <i className="bi bi-file-earmark-diff"></i>{" "}
                  {pageTranslations.forms[language]}
                </button>
              </div>
            </li> */}

            {/* <li className="">
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className="admin-btn nav-link d-flex align-items-center gap-1"
                  id="create-form-link"
                  fdprocessedid="v57r2v"
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    setDisplayComponent((prev) => {
                      setPrevDisplay(prev);
                      return "create-event";
                    });
                    window.history.pushState(
                      { id: "gallery", randomData: window.Math.random() },
                      "title",
                      "/event-portal?page=create-event"
                    );
                  }}
                >
                  <i className="bi bi-file-earmark-plus"></i>{" "}
                  {pageTranslations.createevent[language]}
                </button>
              </div>
            </li> */}
            {userData &&
              userData.roles[0].permissions.some(
                (item) => item.name === "All Dealerships"
              ) && (
                <li className="">
                  <div className="d-grid gap-2">
                    <button
                      type="button"
                      className="admin-btn nav-link d-flex align-items-center gap-1"
                      id="add-dlr-link"
                      fdprocessedid="4u2e3"
                      Concession
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          setPrevDisplay(prev);
                          return "dealership";
                        });
                        window.history.pushState(
                          { id: "gallery", randomData: window.Math.random() },
                          "title",
                          "/event-portal?page=dealership"
                        );
                      }}
                    >
                      <i className="bi bi-shop-window"></i>{" "}
                      {pageTranslations.dealership[language]}{" "}
                      {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                    </button>
                  </div>
                </li>
              )}
            {userData &&
              userData.roles[0].permissions.some(
                (item) => item.name === "All Users"
              ) && (
                <li className="">
                  <div className="d-grid gap-2">
                    {/* <Link to='/create-user' style={{ textDecoration: "none" }}> */}
                    <button
                      type="button"
                      className=" admin-btn nav-link d-flex align-items-center gap-1"
                      id="create-user-link"
                      fdprocessedid="dyv9n"
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          setPrevDisplay(prev);
                          return "manage-users";
                        });
                        window.history.pushState(
                          { prevState: "prev" },
                          "title",
                          "/event-portal?page=manage-users"
                        );
                      }}
                    >
                      <i className="bi bi-people"></i>{" "}
                      {pageTranslations.manageUsers[language]}
                    </button>
                    {/* </Link> */}
                  </div>
                </li>
              )}
            {false && (
              <>
                {userData &&
                  userData.roles[0].permissions.some(
                    (item) => item.name === "Event Report"
                  ) && (
                    <li className="">
                      <div className="d-grid gap-2">
                        <button
                          type="button"
                          className="admin-btn nav-link d-flex align-items-center gap-1"
                          id="add-dlr-link"
                          fdprocessedid="4u2e3"
                          Concession
                          data-bs-dismiss="offcanvas"
                          onClick={() => {
                            setDisplayComponent((prev) => {
                              setPrevDisplay(prev);
                              return "view-event-report";
                            });
                            window.history.pushState(
                              {
                                id: "gallery",
                                randomData: window.Math.random(),
                              },
                              "title",
                              "/event-portal?page=view-event-report"
                              // "/event-portal?page=view-event-report&event_id=35"
                            );
                          }}
                        >
                          <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                          {pageTranslations.eventReports[language]}{" "}
                          {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                        </button>
                      </div>
                    </li>
                  )}
                {userData &&
                  userData.roles[0].permissions.some(
                    (item) => item.name === "Contract And Billing"
                  ) && (
                    <li className="">
                      <div className="d-grid gap-2">
                        <button
                          type="button"
                          className="admin-btn nav-link d-flex align-items-center gap-1"
                          id="add-dlr-link"
                          fdprocessedid="4u2e3"
                          Concession
                          data-bs-dismiss="offcanvas"
                          onClick={() => {
                            setDisplayComponent((prev) => {
                              setPrevDisplay(prev);
                              return "view-billing-report";
                            });
                            window.history.pushState(
                              {
                                id: "gallery",
                                randomData: window.Math.random(),
                              },
                              "title",
                              "/event-portal?page=view-billing-report"
                            );
                          }}
                        >
                          <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                          {pageTranslations.billingReport[language]}{" "}
                          {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                        </button>
                      </div>
                    </li>
                  )}{" "}
              </>
            )}
            {userData &&
              userData.roles[0].permissions.some(
                (item) => item.name === "Sales Report"
              ) && (
                <li className="">
                  <div className="d-grid gap-2">
                    <button
                      type="button"
                      className="admin-btn nav-link d-flex align-items-center gap-1"
                      id="add-dlr-link"
                      fdprocessedid="4u2e3"
                      Concession
                      data-bs-dismiss="offcanvas"
                      onClick={() => {
                        setDisplayComponent((prev) => {
                          setPrevDisplay(prev);
                          return "view-sales-report";
                        });
                        window.history.pushState(
                          {
                            id: "gallery",
                            randomData: window.Math.random(),
                          },
                          "title",
                          "/event-portal?page=view-sales-report"
                        );
                      }}
                    >
                      <i className="bi bi-receipt"></i>{" "}
                      {pageTranslations.salesReport[language]}{" "}
                      {/*<span className="badge bg-danger rounded-pill">NEW</span>*/}
                    </button>
                  </div>
                </li>
              )}
            {/* <li className="nav-item">
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className="admin-btn nav-link"
                  id="manage-notif-link"
                  fdprocessedid="3bt1kb"
                  data-bs-dismiss="offcanvas"
                  onClick={() => {
                    setDisplayComponent((prev) => {
                      setPrevDisplay(prev);
                      return "notifications";
                    });
                    window.history.pushState(
                      {
                        id: "notif management",
                        randomData: window.Math.random(),
                      },
                      "title",
                      "/event-portal?page=notifications"
                    );
                  }}
                >
                  <i className="bi bi-bell"></i>{" "}
                  {pageTranslations.manageNotification[language]}{" "}
                  <span className="badge bg-danger rounded-pill">NEW</span>
                </button>
              </div>
            </li> */}

            {userType && externalUsers.includes(userType) && (
              <li className="">
                <div className="d-grid gap-2">
                  {/* <Link to='/create-user' style={{ textDecoration: "none" }}> */}
                  <button
                    type="button"
                    className=" admin-btn nav-link d-flex align-items-center gap-1"
                    id="create-user-link"
                    fdprocessedid="dyv9n"
                    data-bs-dismiss="offcanvas"
                    onClick={() => {
                      setDisplayComponent((prev) => {
                        setPrevDisplay(prev);
                        return "view-submissons";
                      });
                      window.history.pushState(
                        { prevState: "prev" },
                        "title",
                        "/event-portal?page=view-submissons"
                      );
                    }}
                  >
                    <i className="bi bi-people"></i>{" "}
                    {pageTranslations.viewClientSubmissions[language]}
                  </button>
                  {/* </Link> */}
                </div>
              </li>
            )}
            {/* <li className="">
              <div className="d-grid gap-2">
                

                <button
                  type="button"
                  className="btn admin-btn nav-link d-flex align-items-center gap-1"
                  id="manage-notif-link"
                  data-bs-toggle="modal"
                  data-bs-target="#changePasswordModal"
                  fdprocessedid="3bt1kb"
                  data-bs-dismiss="offcanvas"
                ><i class="bi bi-lock"></i>
                  {pageTranslations.changePassword[language]}
                </button>
                
              </div>
            </li> */}
            <li className="">
              <div className="d-grid gap-2">
                <button
                  type="button"
                  className="btn admin-btn nav-link d-flex align-items-center gap-1"
                  id="manage-notif-link"
                  fdprocessedid="3bt1kb"
                  data-bs-dismiss="offcanvas"
                  onClick={handleLogout}
                >
                  <i className="bi bi-box-arrow-left"></i>{" "}
                  {pageTranslations.logout[language]}{" "}
                </button>
              </div>
            </li>
          </ul>
          {/* <div
            style={{
              width: 350,
              paddingTop: 30,
              margin: "0 auto",
              opacity: 0.3,
              filter: "alpha(opacity=30), blur(5px)",
            }}
          >
            <img style={{ width: 350 }} src={people} alt="People working" />
          </div> */}
        </div>
        <div className="offcanvas-footer text-black text-center w-75  mx-auto">
          <p className="fw-lighter smalltext">
            {translations.footerTxt1[language]}© {currentYear} FIDGI
            Communications. {translations.footerTxt2[language]}
          </p>
        </div>
      </div>
      <ModalAdminQuickAdd
        setDisplayComponent={setDisplayComponent}
        setEventTrigger={setEventTrigger}
        dealerships={dealerships}
        setDealerTrigger={setDealerTrigger}
        isDarkMode={isDarkMode}
      />
      <ChangePasswordModal setIsLoading={setIsLoading} />
    </div>
  );
};

export default AdminNav;
