import React from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
const AppointmentsSection = ({ isDarkMode }) => {
  const { language } = useLanguage();
  return (
    <div className="py-4">
      <div className="d-flex gap-2">
        <div
          className="d-flex flex-column align-items-center justify-content-center rounded-1"
          style={{
            backgroundColor: isDarkMode
              ? "rgba(243,243,243,0.2)"
              : "rgba(243,243,243)",
            minHeight: "300px",
            width: "20%",
          }}
        >
          <p className="text-white fw-bold">
            {" "}
            {/* Avg {translations.forms.formFields.amount[language]} total{" "} */}
          </p>
          <p className="fw-bolder" style={{ fontSize: "36px" }}>
            {/* CAD {numeral (graphData?.aveAmount).format("0,000.00")} */}4553
          </p>
        </div>
        <div
          className="d-flex flex-column align-items-center justify-content-center rounded-1"
          style={{
            backgroundColor: isDarkMode
              ? "rgba(243,243,243,0.2)"
              : "rgba(243,243,243)",
            minHeight: "300px",
            width: "20%",
          }}
        >
          <p className="text-white fw-bold">
            {" "}
            {/* Total {translations.forms.formFields.amount[language]} total{" "} */}
          </p>
          <p className=" fw-bolder" style={{ fontSize: "36px" }}>
            {/* CAD {numeral (graphData?.totalAmount).format("0,000.00")} */}
            2311
          </p>
        </div>
        <div
          className=" d-flex flex-column align-items-center justify-content-center rounded-1"
          style={{
            // boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
            background: isDarkMode
              ? "rgba(243,243,243,0.2)"
              : "rgba(243,243,243)",
            minHeight: "300px",
            width: "20%",
          }}
        >
          <p className="fw-bold">
            {" "}
            {/* Max. ({graphData?.vendorWithMaxAmount?.name}) */}
          </p>
          <p className="fw-bolder" style={{ fontSize: "36px" }}>
            {/* CAD {numeral (graphData?.vendorWithMaxAmount?.total_amount).format("0,000.00")} */}
            345
          </p>
        </div>
        <div
          className="p-2 rounded-1"
          style={{
            // boxShadow: "0 0 40px rgba(0, 0, 0, 0.15)",
            minHeight: "300px",
            width: "40%",
            background: isDarkMode
              ? "#f3f3f333"
              : "rgba(243,243,243)",
          }}
        >
          <div
            className=""
            style={{
              display: "flex",
              flexDirection: "column",
              overflowX: "auto",
              maxHeight: "300px",
              //   maxHeight: "calc(100vh - 200px)",
            }}
          >
            <table className="align-items-center">
              <thead
                style={{
                  position: "sticky",
                  top: "0",
                  zIndex: "1000", // Ensure it's higher than content
                  background: isDarkMode
                    ? "#f3f3f333"
                    : "rgba(243,243,243)",
                  boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Optional: Add shadow to make the header stand out
                }}
              >
                <tr>
                  <th>
                    <p className="fw-bold">Name</p>
                  </th>
                  <th>
                    <p className="fw-bold">Appointment Date</p>
                  </th>
                  <th>
                    <p className="fw-bold">Cancelled Appointments</p>
                  </th>
                  <th>
                    <p className="fw-bold">Total Appointments</p>
                  </th>
                </tr>
              </thead>
              <tbody className="">
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
                <tr>
                  <td>Appointment 1</td>
                  <td className="text-center">304</td>
                  <td className="text-center">234</td>
                  <td className="text-center">538</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsSection;
