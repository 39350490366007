import { useEffect, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { toast } from "react-toastify";
import { ApiUrl } from "./ApiUrl";
import Select from "react-select";
import { useLanguage } from "./languageContext";
import translations from "./translation.json";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreatePackage from "./createPackage";
import AddDealershipModal from "./AddDealershipModal";
import { getISOWeek } from "date-fns/getISOWeek";
import CreateFormModal from "./createFormModal";

const CreatePrivateEvent = ({
  dealerships,
  setDealerTrigger,
  setIsLoading,
  isDarkMode,
  editMode = false,
  setDisplayComponent,
  users,
}) => {
  const [isOpenAll, setIsOpenAll] = useState(false);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("userData"));
  const [submitPressed, setSubmitPressed] = useState(false);
  const [eventData, seteventData] = useState(null);
  const [parentForms, setParentForms] = useState([]);
  const [parentForms2, setParentForms2] = useState([]);
  const token = localStorage.getItem("token");
  const role = localStorage.getItem("userType");
  const { language } = useLanguage();
  const pageTranslations = translations["forms"];
  const [brands, setBrands] = useState();
  const [packages, setallPackages] = useState();
  const [eventId, setEventId] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [selectedDealership, setSelectedDealership] = useState(null);
  const [foundRep, setfoundRep] = useState(false);
  const [foundBrand, setfoundBrand] = useState(false);
  const [parentId, setParentId] = useState("");
  const [childId, setChildId] = useState("");

  const managerOptions =
    users &&
    users.projectMangers.length > 0 &&
    users.projectMangers.map((user) => ({
      value: user.id,
      label: user.name,
    }));
  const repOptions =
    users &&
    users.representatives.length > 0 &&
    users.representatives.map((user) => ({
      value: user.id,
      label: user.name,
    }));
  const animatorOptions =
    users &&
    users.animaters.length > 0 &&
    users.animaters.map((user) => ({
      value: user.id,
      label: user.name,
    }));
  const agentOptions =
    users &&
    users.agents.length > 0 &&
    users.agents.map((user) => ({
      value: user.id,
      label: user.name,
    }));

  const commonDropdown = [
    { label: translations["dropdown"].yes[language], value: "Yes" },
    { label: translations["dropdown"].no[language], value: "No" },
  ];

  const handleToggleAll = () => {
    setIsOpenAll((prevIsOpenAll) => !prevIsOpenAll);
  };
  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });
  const initialData = {
    nom_vp: editMode && eventData ? eventData.nom_vp : "",
    week: editMode && eventData ? eventData.week : "",
    start_date: editMode && eventData ? eventData.start_date : "",
    end_date: editMode && eventData ? eventData.end_date : "",
    dealership_id: editMode && eventData ? eventData.dealership_id : "",
    project_manager_id:
      editMode && eventData ? eventData.project_manager_id : "",
    sales_rep_id: editMode && eventData ? eventData.sales_rep_id : "",
    animator_id: editMode && eventData ? eventData.animator_id : "",
    package_id: editMode && eventData ? eventData.package_id : "",
    comments: editMode && eventData ? eventData.comments : "",
    mailing: editMode && eventData ? eventData.mailing : "",
    web: editMode && eventData ? eventData.web : "",
    banner: editMode && eventData ? eventData.banner : "",
    news_letter: editMode && eventData ? eventData.news_letter : "",
    facebook: editMode && eventData ? eventData.facebook : "",
    ad_words: editMode && eventData ? eventData.ad_words : "",
    sms: editMode && eventData ? eventData.sms : "",
    clients: editMode && eventData ? eventData.clients : "",
    inactive: editMode && eventData ? eventData.inactive : "",
    conquest: editMode && eventData ? eventData.conquest : "",
    massif: editMode && eventData ? eventData.massif : "",
    amount: editMode && eventData ? eventData.amount : "",
    confirmation_agent_id:
      editMode && eventData ? eventData.confirmation_agent_id : "",
    no_show_agent_id: editMode && eventData ? eventData.no_show_agent_id : "",
    carton: editMode && eventData ? eventData.carton : "",
    brand_id: editMode && eventData ? eventData.brand_id : "",
    invoice: editMode && eventData ? eventData.invoice : "",
    amount_animator: editMode && eventData ? eventData.amount_animator : "",
    invoice_animator: editMode && eventData ? eventData.invoice_animator : "",
    commission_paid: editMode && eventData ? eventData.commission_paid : "",
    instructions: editMode && eventData ? eventData.instructions : "",
    status: editMode && eventData ? eventData.status : "",
  };

  const validationSchema = Yup.object().shape({
    nom_vp: Yup.string().required(
      pageTranslations.validationTranslations.nom_vp[language]
    ),
    start_date: Yup.date().required(
      pageTranslations.validationTranslations.start_date[language]
    ),
    end_date: Yup.date().required(
      pageTranslations.validationTranslations.end_date[language]
    ),
    dealership_id: Yup.number().required(
      translations.quickCreationModal.validationsText.dealershipText[language]
    ),
    project_manager_id: Yup.number().required(
      pageTranslations.validationTranslations.manager[language]
    ),
    sales_rep_id: Yup.number().required(
      pageTranslations.validationTranslations.sales_representative[language]
    ),
    animator_id: Yup.number().required(
      pageTranslations.validationTranslations.animator[language]
    ),
    package_id: Yup.number().required(
      pageTranslations.validationTranslations.package[language]
    ),
    comments: Yup.string().required(
      pageTranslations.validationTranslations.comments[language]
    ),
    mailing: Yup.string().required(
      pageTranslations.validationTranslations.mailing[language]
    ),
    web: Yup.string().required(
      pageTranslations.validationTranslations.web[language]
    ),
    banner: Yup.string().required(
      pageTranslations.validationTranslations.banner[language]
    ),
    news_letter: Yup.string().required(
      pageTranslations.validationTranslations.newsletter[language]
    ),
    facebook: Yup.string().required(
      pageTranslations.validationTranslations.facebook[language]
    ),
    ad_words: Yup.string().required(
      pageTranslations.validationTranslations.adwords[language]
    ),
    sms: Yup.string().required(
      pageTranslations.validationTranslations.sms[language]
    ),
    clients: Yup.string().required(
      pageTranslations.validationTranslations.clients[language]
    ),
    inactive: Yup.string().required(
      pageTranslations.validationTranslations.inactive[language]
    ),
    conquest: Yup.string().required(
      pageTranslations.validationTranslations.conquest[language]
    ),
    massif: Yup.string().required(
      pageTranslations.validationTranslations.massif[language]
    ),
    amount: Yup.string().required(
      pageTranslations.validationTranslations.amount[language]
    ),
    confirmation_agent_id: Yup.string().required(
      pageTranslations.validationTranslations.confirmAgent[language]
    ),
    no_show_agent_id: Yup.string().required(
      pageTranslations.validationTranslations.noShowAgent[language]
    ),
    carton: Yup.string().required(
      pageTranslations.validationTranslations.carton[language]
    ),
    brand_id: Yup.string().required(
      translations.quickCreationModal.validationsText.brandText[language]
    ),
    invoice: Yup.string().required(
      pageTranslations.validationTranslations.invoice[language]
    ),
    amount_animator: Yup.string().required(
      pageTranslations.validationTranslations.amountAnimator[language]
    ),
    invoice_animator: Yup.string().required(
      pageTranslations.validationTranslations.invoiceAnimator[language]
    ),
    commission_paid: Yup.string().required(
      pageTranslations.validationTranslations.commissionPaid[language]
    ),
    instructions: Yup.string().required(
      pageTranslations.validationTranslations.instructions[language]
    ),
    status: Yup.string().required(
      pageTranslations.validationTranslations.status[language]
    ),
  });

  const handleSubmit = async (values, { resetForm }, error) => {
    setIsLoading(true);
    try {
      const url = `${ApiUrl}/api/user/private-events${
        editMode ? `/${eventId}` : ""
      }`;
      const method = editMode ? "PUT" : "POST";
      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(values),
      });
      // console.log("res", res)
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        toast.success(data.message);
        navigate("/event-portal");
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  const options = dealerships.map((dealership) => ({
    value: dealership.id,
    label: dealership.name,
  }));
  const brandOptions =
    brands &&
    brands.length > 0 &&
    brands.map((brand) => ({
      value: brand.id,
      label: brand.name,
    }));
  const statusOptions = [
    {
      label: translations.usersPage.createUser.statusOptions.active[language],
      value: "Active",
    },
    {
      label:
        translations.usersPage.createUser.statusOptions.cancelled[language],
      value: "Cancelled",
    },
    {
      label: translations.usersPage.createUser.statusOptions.waiting[language],
      value: "Waiting",
    },
    {
      label:
        translations.usersPage.createUser.statusOptions.completed[language],
      value: "Completed",
    },
  ];

  useEffect(() => {
    const getBrands = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(ApiUrl + "/api/user/get-brands", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          setBrands(data.list_of_brands);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error("Error fetching dealerships:", error);
        // Handle error or show a toast if needed
      }
    };
    const getPackagess = async () => {
      try {
        const res = await fetch(ApiUrl + "/api/user/package-index/allPackage", {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await res.json();

        if (data.status === "success") {
          const packageOptions =
            data.packages &&
            data.packages.length > 0 &&
            data.packages.map((item) => ({
              value: item.id,
              label: item.name,
            }));
          setallPackages(packageOptions);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching dealerships:", error);
        // Handle error or show a toast if needed
      }
    };

    getBrands();
    getPackagess();
  }, [token]);

  // useEffect(() => {
  //   document.getElementById("dateBtn").click();
  // }, []);

  useEffect(() => {
    const getEventData = async () => {
      // setIsLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/private-events/" + eventId,

          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          seteventData(data?.privateEvent);
          setParentForms(data?.parentForm);
          setSelectedPackage(data?.privateEvent.package_id);
          setSelectedDealership(data?.privateEvent.dealership_id);
          // setfoundRep
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
    };
    if (eventId) {
      getEventData(eventId);
    }
  }, [eventId]);

  const createEvent = async () => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/create-event", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ event_id: eventId }),
      });
      const data = await res.json();
      if (data.status === "success") {
        setDisplayComponent("edit-form");
        window.history.pushState(
          { id: "gallery", randomData: window.Math.random() },
          "title",
          "/event-portal?page=edit-form&event_id=" +
            eventId +
            "&form_id=" +
            data.event_url
        );
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };

  const getRep = async (dealerId, values, setValues) => {
    setIsLoading(true);

    try {
      const res = await fetch(
        ApiUrl + "/api/user/select-dealership/" + dealerId,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          // body: JSON.stringify({ event_id: eventId }),
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        setfoundRep(data.representative !== null ? true : false);
        setfoundBrand(data.brand !== null ? true : false);
        setValues({
          ...values,
          dealership_id: dealerId,
          sales_rep_id:
            data.representative !== null ? data.representative.id : "",
          brand_id: data.brand !== null ? data.brand.id : "",
        });
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };

  const checkParent = async (event_Id) => {
    setIsLoading(true);
    try {
      const res = await fetch(
        ApiUrl + "/api/user/check-parentform/" + event_Id,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await res.json();
      if (data.status === "success") {
        const options =
          data?.parentForm &&
          data?.parentForm.length > 0 &&
          data.parentForm.map((item) => {
            return { label: item?.indexed_date, value: item.id };
          });
        setParentForms2(options);

        setEventId(event_Id);
        // setParentId(data?.parentForm);
        var createModal = new window.bootstrap.Modal(
          document.getElementById("createForm")
        );
        createModal.show();
      } else {
        // toast.error(data.message);
        setDisplayComponent("create-event");

        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          "/event-portal?page=create-event&parent_id=" +
            event_Id +
            "&event-navigate=true"
        );
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };

  const changeStatus = async (status, event_Id) => {
    setIsLoading(true);
    try {
      const res = await fetch(ApiUrl + "/api/user/change-status", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ status: status, id: event_Id }),
      });
      const data = await res.json();
      if (data.status === "success") {
        toast.success(data.message);
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      toast.error("Something went wrong");
    } finally {
    }
  };

  const handleCopy = async () => {
    try {
      setIsLoading(true);
      const res = await fetch(ApiUrl + "/api/user/copy-parentform?page=", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          parent_id: parentId,
          child_id: childId,
          event_id: eventId,
          // week: week,
        }),
      });
      const data = await res.json();
      if (data.status === "success") {
        setDisplayComponent("edit-form");
        window.history.pushState(
          {
            id: "gallery",
            randomData: window.Math.random(),
          },
          "title",
          `/event-portal?page=edit-form&event_id=${eventId}&form_id=${data.event_url}`
        );
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="create-event">
      <div class="container maincont">
        <div id="app" className="appcountainer2">
          <AddDealershipModal
            setDealerTrigger={setDealerTrigger}
            setIsLoading={setIsLoading}
            setSelectedDealership={setSelectedDealership}
            dealerships={dealerships}
            users={users}
            quickAdd={false}
          />
          <CreatePackage
            setDealerTrigger={setDealerTrigger}
            setIsLoading={setIsLoading}
            isDarkMode={isDarkMode}
            setSelectedPackage={setSelectedPackage}
            packages={packages}
            setallPackages={setallPackages}
          />
          <CreateFormModal
            isDarkMode={isDarkMode}
            handleCopy={handleCopy}
            setParentId={setParentId}
            eventId={eventId}
            setEventId={setEventId}
            setDisplayComponent={setDisplayComponent}
            parentForms={parentForms2}
            setChildId={setChildId}
          />
          <Formik
            initialValues={initialData}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ errors, values, setValues, setFieldValue }) => (
              <Form>
                {/* Display a toast for each field with an error */}
                {(() => {
                  let stopLoop = false;

                  submitPressed &&
                    Object.keys(errors).length > 0 &&
                    Object.keys(errors).some((field, index, array) => {
                      if (stopLoop) return;
                      toast.error(errors[field]);

                      if (index === array.length - 1) {
                        setSubmitPressed(false);
                        stopLoop = true;
                      }

                      return stopLoop;
                    });
                })()}

                <div class="edit-form-form">
                  <div class="headersec">
                    <nav class="navbar navbar-expand-md navbar-light">
                      <div class="d-flex justify-content-between w-100">
                        <div>
                          <h2 className={`${isDarkMode ? "darkthead" : ""}`}>
                            {editMode
                              ? eventData?.nom_vp +
                                "  (" +
                                eventData?.week +
                                ")"
                              : pageTranslations.title[language]}
                          </h2>
                        </div>
                        <div
                          className="d-flex gap-2"
                          style={{ marginRight: "16px" }}
                        >
                          {false && (
                            <button
                              type="button"
                              className="admin-btn btn btn-primary"
                              onClick={() => {
                                setDisplayComponent("view-billing-report");

                                window.history.pushState(
                                  {
                                    id: "gallery",
                                    randomData: window.Math.random(),
                                  },
                                  "title",
                                  "/event-portal?page=view-billing-report&event_id=35"
                                );
                              }}
                            >
                              <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                              {
                                translations.EventsPortal.eventsTableButtons
                                  .vieBillingReport[language]
                              }
                            </button>
                          )}
                        </div>
                        {false && (
                          <>
                            <button
                              class="navbar-toggler"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#navbarNav"
                              aria-controls="navbarNav"
                              aria-expanded="false"
                              aria-label="Toggle navigation"
                            >
                              <span class="navbar-toggler-icon"></span>
                            </button>
                            <div
                              class="collapse navbar-collapse justify-content-end"
                              id="navbarNav"
                            >
                              <ul class="navbar-nav">
                                <li class="nav-item">
                                  <button
                                    id="dateBtn"
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    href="#multiCollapseDate"
                                    role="button"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseDate"
                                  >
                                    <i class="bi bi-calendar"></i>{" "}
                                    {pageTranslations.datesBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#multiCollapseOptions"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseOptions"
                                  >
                                    <i class="bi bi-people-fill"></i>{" "}
                                    {pageTranslations.conquestBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#multiCollapseInfosvente"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseInfosvente"
                                  >
                                    <i class="bi bi-info-circle"></i>{" "}
                                    {pageTranslations.salesInfoBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#multiCollapseInfoscontact"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseInfoscontact"
                                  >
                                    <i class="bi bi-person-lines-fill"></i>{" "}
                                    {pageTranslations.contactBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#multiCollapseInfosequipe"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseInfosequipe"
                                  >
                                    <i class="bi bi-person-badge"></i>{" "}
                                    {pageTranslations.teamBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    class="admin-btn btn-primary"
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target="#multiCollapseInfospromos"
                                    aria-expanded="false"
                                    aria-controls="multiCollapseInfospromos"
                                  >
                                    <i class="bi bi-stars"></i>{" "}
                                    {pageTranslations.promosBtn[language]}
                                  </button>
                                </li>
                                <li class="nav-item">
                                  <button
                                    className="admin-btn btn-primary"
                                    type="button"
                                    onClick={handleToggleAll}
                                  >
                                    <i className="bi bi-folder2-open"></i>{" "}
                                    {isOpenAll
                                      ? pageTranslations.closeAllBtn[language]
                                      : pageTranslations.openAllBtn[language]}
                                  </button>
                                  {/* <button class="admin-btn btn-primary" type="button" data-bs-toggle="collapse" data-bs-target=".multi-collapse" aria-expanded="false" aria-controls="multiCollapseDate multiCollapseOptions multiCollapseInfosvente multiCollapseInfoscontact multiCollapseInfosequipe multiCollapseInfospromos"><i class="bi bi-folder2-open"></i> Open All</button> */}
                                </li>
                              </ul>
                            </div>
                          </>
                        )}
                        {editMode && (
                          // <div className="mx-2">
                          //   <h3 id={ eventData?.status === "Active" ||
                          //                     eventData?.status === "Completed" ? "activeStatus" :eventData?.status === "Waiting"
                          //                     ? "waitingStatus"
                          //                     : "cancelStatus" } >
                          //     {eventData?.status}
                          //   </h3>
                          // </div>
                          <div className="d-flex align-items-center mx-2 col-2">
                            <Field
                              name="status"
                              render={({ field, form }) => (
                                <>
                                  <div
                                    style={{
                                      width: "10px",
                                      height: "10px",
                                      backgroundColor:
                                        field.value === "Active" ||
                                        field.value === "Completed"
                                          ? "#34DBB4"
                                          : field.value === "Waiting"
                                          ? "orange"
                                          : "red",
                                      borderRadius: "50%",
                                      marginRight: "8px", // Adjust the spacing between the circle and the dropdown
                                    }}
                                  ></div>
                                  <Select
                                    className="selectpicker w-100"
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    // name="status"
                                    isDisabled={userData && !userData.roles[0].permissions.some(
                                      (item) =>
                                        item.name === "Private Event Show"
                                    ) }
                                    options={statusOptions}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isSearchable={false}
                                    value={
                                      (statusOptions &&
                                        statusOptions.length > 0 &&
                                        statusOptions.find(
                                          (option) =>
                                            option.value === field.value
                                        )) || {
                                        value: "",
                                        label:
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      }
                                    }
                                    onChange={(selectedOption) => {
                                      form.setFieldValue(
                                        field.name,
                                        selectedOption.value
                                      );

                                      changeStatus(
                                        selectedOption.value,
                                        eventData.id
                                      );
                                    }}
                                    // maxMenuHeight={"120px"}
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        width: "100%",
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "white",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".45rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.18rem 0.55rem",
                                        width: "100%", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                        width: "100%",
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                </>
                              )}
                            />
                          </div>
                        )}
                      </div>
                    </nav>
                  </div>
                  <div className="p-2">
                    <h3 className={`mt-2 ${isDarkMode ? "darkthead" : ""}`}>
                      {translations.eventHeadings.general[language]}
                    </h3>
                    <div class="row">
                      <div className="col">
                        <div className="row">
                          <label class="form-label">
                            {
                              translations.quickCreationModal.dealership[
                                language
                              ]
                            }
                          </label>
                          <div
                            class={
                              editMode
                                ? "col-12"
                                : userData &&
                                  userData.roles[0].permissions.some(
                                    (item) => item.name === "Create Dealership"
                                  )
                                ? "col-8"
                                : ""
                            }
                          >
                            <div class="mb-3">
                              <Field
                                name="dealership_id"
                                render={({ field, form }) => (
                                  <Select
                                    className="selectpicker"
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    options={[...options]}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isSearchable
                                    value={
                                      options.find(
                                        (option) =>
                                          option.value === selectedDealership
                                      ) || {
                                        value: "",
                                        label:
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      }
                                    }
                                    onChange={(selectedOption) => {
                                      // form.setFieldValue(
                                      //   field.name,
                                      //   selectedOption.value
                                      // );
                                      setSelectedDealership(
                                        selectedOption.value
                                      );
                                      getRep(
                                        selectedOption.value,
                                        values,
                                        setValues
                                      );
                                    }}
                                    maxMenuHeight={"120px"}
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "white",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".45rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                )}
                              />
                              {/* <Field as="select" class="selectpicker form-select" name="dealer" aria-label="Dealer"
                                id="dealership-name2" data-show-subtext="true" data-live-search="true" fdprocessedid="qo2vc8">
                                <option value="" selected="">Select</option>

                                {dealerships && dealerships.map((dealership) => (
                                  < option key={dealership.id} value={dealership.id}>{dealership.name}</option>
                                ))}
                              </Field> */}
                              <ErrorMessage
                                name="dealership_id"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          {!editMode &&
                            userData &&
                            userData.roles[0].permissions.some(
                              (item) => item.name === "Create Dealership"
                            ) && (
                              <div class="col-4">
                                <div class="mb-3">
                                  <button
                                    type="button"
                                    class="client-btn btn-success"
                                    id="add-dlr-trigger"
                                    // data-bs-toggle="modal"
                                    // data-bs-target="#AddDealerModal"
                                    data-bs-toggle="modal"
                                    data-bs-target="#myModalCon"
                                    fdprocessedid="wqiuds"
                                  >
                                    <i class="bi bi-plus-circle"></i>{" "}
                                    {
                                      translations["quickCreationModal"].addBtn[
                                        language
                                      ]
                                    }
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {translations.quickCreationModal.brand[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="brand_id"
                            disabled={editMode || foundBrand}
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={brandOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isDisabled={editMode || foundBrand}
                                isSearchable
                                value={
                                  (brandOptions &&
                                    brandOptions.length > 0 &&
                                    brandOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label: foundBrand
                                      ? ""
                                      : translations["dropdown"].select[
                                          language
                                        ],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"85px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? editMode || foundBrand
                                        ? "#ced4da66"
                                        : "transparent"
                                      : editMode || foundBrand
                                      ? "#ced4da66"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                    display:
                                      editMode || foundRep ? "none" : "block",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="brand_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            Nom VP
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="text"
                            name="nom_vp"
                            disabled={true}
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode
                                ? "#ced4da66"
                                : "#ced4da66",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="nom_vp"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col">
                        <div className="row">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.package[language]}
                          </label>
                          <div
                            className={`${
                              userData &&
                              userData.roles[0].permissions.some(
                                (item) => item.name === "Package Store"
                              )
                                ? "col-8"
                                : "col-12"
                            }`}
                          >
                            <div class="mb-3">
                              {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                              <Field
                                name="package_id"
                                render={({ field, form }) => (
                                  <Select
                                    className="selectpicker"
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    options={packages}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isSearchable
                                    value={
                                      (packages &&
                                        packages.length > 0 &&
                                        packages.find(
                                          (option) =>
                                            option.value === selectedPackage
                                        )) || {
                                        value: "",
                                        label:
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      }
                                    }
                                    onChange={(selectedOption) => {
                                      form.setFieldValue(
                                        field.name,
                                        selectedOption.value
                                      );
                                      setSelectedPackage(selectedOption.value);
                                    }}
                                    maxMenuHeight={"120px"}
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "white",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".45rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="package_id"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                          {userData &&
                            userData.roles[0].permissions.some(
                              (item) => item.name === "Package Store"
                            ) && (
                              <div class="col-4">
                                <div class="mb-3">
                                  <button
                                    type="button"
                                    class="client-btn btn-success"
                                    id="add-dlr-trigger"
                                    data-bs-toggle="modal"
                                    data-bs-target="#packageModal"
                                    fdprocessedid="wqiuds"
                                  >
                                    <i class="bi bi-plus-circle"></i>{" "}
                                    {
                                      translations["quickCreationModal"].addBtn[
                                        language
                                      ]
                                    }
                                  </button>
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                      <div class="col">
                        <div class="row">
                          <div class="col">
                            <div class="mb-3">
                              <label for="last-sale-date" class="form-label">
                                {
                                  pageTranslations.privateEventformFields
                                    .startDate[language]
                                }
                              </label>
                              <Field
                                type="date"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "transparent"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                                name="start_date"
                                class={`form-control ${
                                  isDarkMode ? "custom-date" : ""
                                }`}
                                onChange={(e) => {
                                  const date = new Date(e.target.value);

                                  setValues({
                                    ...values,
                                    start_date: e.target.value,
                                    week: getISOWeek(date),
                                  });
                                }}
                                id="last-sale-date"
                              />
                              <ErrorMessage
                                name="start_date"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                            {false && (
                              <div class="mb-3">
                                <Field
                                  name="dealer"
                                  render={({ field, form }) => (
                                    <Select
                                      className="selectpicker"
                                      aria-labelledby="dealership-name-quick"
                                      // isClearable
                                      options={[
                                        {
                                          value: "",
                                          label:
                                            translations["dropdown"].select[
                                              language
                                            ],
                                        },
                                        ...options,
                                      ]}
                                      placeholder={
                                        translations["dropdown"].select[
                                          language
                                        ]
                                      }
                                      isSearchable
                                      value={
                                        options.find(
                                          (option) =>
                                            option.value === field.value
                                        ) || {
                                          value: "",
                                          label:
                                            translations["dropdown"].select[
                                              language
                                            ],
                                        }
                                      }
                                      onChange={(selectedOption) =>
                                        form.setFieldValue(
                                          field.name,
                                          selectedOption.value
                                        )
                                      }
                                      styles={{
                                        input: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black", // Color of the text when typing
                                        }),
                                        control: (provided, state) => ({
                                          ...provided,
                                          backgroundColor: isDarkMode
                                            ? "transparent"
                                            : "white",
                                          border: state.isFocused
                                            ? "1px solid #80bdff"
                                            : isDarkMode
                                            ? "1px solid #495057"
                                            : "1px solid #dee2e6", // Adjust the border color for focus
                                          boxShadow: state.isFocused
                                            ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                            : "none", // Bootstrap's box shadow for focus
                                          borderRadius: ".25rem",
                                        }),
                                        valueContainer: (provided) => ({
                                          ...provided,
                                          padding: "0.375rem 0.75rem", // Bootstrap's padding for the value container
                                        }),
                                        indicatorSeparator: (provided) => ({
                                          ...provided,
                                          display: "none", // Hide the indicator separator
                                        }),
                                        dropdownIndicator: (provided) => ({
                                          ...provided,
                                          padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#868686",
                                        }),
                                        menu: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                          backgroundColor: isDarkMode
                                            ? "#1f2023"
                                            : "white",
                                          margin: "0", // Remove default margin
                                        }),
                                        placeholder: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "#333333", // Specify your desired color for the placeholder
                                          // Additional styles for placeholder
                                        }),
                                        singleValue: (provided) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                        }),
                                        option: (provided, state) => ({
                                          ...provided,
                                          color: isDarkMode
                                            ? "#ffffff"
                                            : "black",
                                          backgroundColor: state.isHovered
                                            ? "#80bdff"
                                            : isDarkMode
                                            ? "#1f2023"
                                            : "white",
                                          ":hover": {
                                            backgroundColor: isDarkMode
                                              ? "#34DBB4"
                                              : "#34DBB4",
                                          },
                                        }),
                                      }}
                                    />
                                  )}
                                />
                                {/* <Field as="select" class="selectpicker form-select" name="dealer" aria-label="Dealer"
                                  id="dealership-name2" data-show-subtext="true" data-live-search="true" fdprocessedid="qo2vc8">
                                  <option value="" selected="">Select</option>

                                  {dealerships && dealerships.map((dealership) => (
                                    < option key={dealership.id} value={dealership.id}>{dealership.name}</option>
                                  ))}
                                </Field> */}
                                <ErrorMessage
                                  name="dealer"
                                  component="div"
                                  className="error text-danger"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="last-sale-date" class="form-label">
                            {
                              pageTranslations.privateEventformFields.endDate[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="date"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            name="end_date"
                            class={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            id="last-sale-date"
                          />
                          <ErrorMessage
                            name="end_date"
                            component="div"
                            className="error text-danger"
                          />
                        </div>{" "}
                      </div>
                    </div>
                    <div className="row">
                      <div class="col">
                        <div class="mb-3">
                          <label class="form-label">
                            {
                              pageTranslations.formFields.sales_representative[
                                language
                              ]
                            }
                          </label>
                          <Field
                            name="sales_rep_id"
                            disabled={editMode || foundRep}
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                isDisabled={editMode || foundRep}
                                // isClearable
                                options={repOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (repOptions &&
                                    repOptions.length > 0 &&
                                    repOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label: foundRep
                                      ? ""
                                      : translations["dropdown"].select[
                                          language
                                        ],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? editMode || foundRep
                                        ? "#ced4da66"
                                        : "transparent"
                                      : editMode || foundRep
                                      ? "#ced4da66"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                    display:
                                      editMode || foundRep ? "none" : "block",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="sales_rep_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.animator[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="animator_id"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={animatorOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (animatorOptions &&
                                    animatorOptions.length > 0 &&
                                    animatorOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="animator_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label class="form-label">
                            {
                              pageTranslations.formFields.projectManager[
                                language
                              ]
                            }
                          </label>
                          <Field
                            name="project_manager_id"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={managerOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (managerOptions &&
                                    managerOptions.length > 0 &&
                                    managerOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />

                          <ErrorMessage
                            name="project_manager_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      {false && (
                        <>
                          <div class="col-4">
                            <div class="mb-3">
                              <label
                                for="annual-sales-count"
                                class="form-label"
                              >
                                {
                                  translations.usersPage.createUser.status[
                                    language
                                  ]
                                }
                              </label>
                              {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                              <Field
                                name="status"
                                render={({ field, form }) => (
                                  <Select
                                    className="selectpicker"
                                    aria-labelledby="dealership-name-quick"
                                    // isClearable
                                    options={statusOptions}
                                    placeholder={
                                      translations["dropdown"].select[language]
                                    }
                                    isSearchable={false}
                                    value={
                                      (statusOptions &&
                                        statusOptions.length > 0 &&
                                        statusOptions.find(
                                          (option) =>
                                            option.value === field.value
                                        )) || {
                                        value: "",
                                        label:
                                          translations["dropdown"].select[
                                            language
                                          ],
                                      }
                                    }
                                    onChange={(selectedOption) => {
                                      form.setFieldValue(
                                        field.name,
                                        selectedOption.value
                                      );
                                    }}
                                    maxMenuHeight={"120px"}
                                    styles={{
                                      input: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                      }),
                                      control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: isDarkMode
                                          ? "transparent"
                                          : "white",
                                        border: state.isFocused
                                          ? "1px solid #80bdff"
                                          : isDarkMode
                                          ? "1px solid #495057"
                                          : "1px solid #dee2e6", // Adjust the border color for focus
                                        boxShadow: state.isFocused
                                          ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                          : "none", // Bootstrap's box shadow for focus
                                        borderRadius: ".45rem",
                                      }),
                                      valueContainer: (provided) => ({
                                        ...provided,
                                        padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                      }),
                                      indicatorSeparator: (provided) => ({
                                        ...provided,
                                        display: "none", // Hide the indicator separator
                                      }),
                                      dropdownIndicator: (provided) => ({
                                        ...provided,
                                        padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#868686",
                                      }),
                                      menu: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        margin: "0", // Remove default margin
                                      }),
                                      placeholder: (provided) => ({
                                        ...provided,
                                        color: isDarkMode
                                          ? "#ffffff"
                                          : "#333333", // Specify your desired color for the placeholder
                                        // Additional styles for placeholder
                                      }),
                                      singleValue: (provided) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                      }),
                                      option: (provided, state) => ({
                                        ...provided,
                                        color: isDarkMode ? "#ffffff" : "black",
                                        backgroundColor: state.isHovered
                                          ? "#80bdff"
                                          : isDarkMode
                                          ? "#1f2023"
                                          : "white",
                                        ":hover": {
                                          backgroundColor: isDarkMode
                                            ? "#34DBB4"
                                            : "#34DBB4",
                                        },
                                      }),
                                    }}
                                  />
                                )}
                              />
                              <ErrorMessage
                                name="status"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>

                          <div class="col-4">
                            <div class="mb-3">
                              <label
                                for="annual-sales-count"
                                class="form-label"
                              >
                                {
                                  translations.EventsPortal.eventsTableHeader
                                    .week[language]
                                }
                              </label>
                              {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                              <Field
                                type="text"
                                name="week"
                                disabled={true}
                                class="form-control"
                                id="event-comm"
                                style={{
                                  backgroundColor: isDarkMode
                                    ? "#ced4da66"
                                    : "",
                                  color: isDarkMode ? "#ffffff" : "#000000",
                                  border: isDarkMode
                                    ? "1px solid #495057"
                                    : "1px solid #dee2e6",
                                }}
                              ></Field>
                              <ErrorMessage
                                name="week"
                                component="div"
                                className="error text-danger"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              pageTranslations.formFields.confirmationAgent[
                                language
                              ]
                            }
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="confirmation_agent_id"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={agentOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (agentOptions &&
                                    agentOptions.length > 0 &&
                                    agentOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="confirmation_agent_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.noShowAgent[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="no_show_agent_id"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={agentOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (agentOptions &&
                                    agentOptions.length > 0 &&
                                    agentOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="no_show_agent_id"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              pageTranslations.formFields.promoAndRequestSection
                                .comments[language]
                            }
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            as="textarea"
                            type="text"
                            name="comments"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="comments"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <h3 className={`mt-4 ${isDarkMode ? "darkthead" : ""}`}>
                      {translations.eventHeadings.components[language]}
                    </h3>
                    <div className="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.carton[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="carton"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={commonDropdown}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  commonDropdown.find(
                                    (option) => option.value === field.value
                                  ) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="carton"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.mailing[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="number"
                            name="mailing"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="mailing"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.clients[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="number"
                            name="clients"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="clients"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.inactive[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="number"
                            name="inactive"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="inactive"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              pageTranslations.formFields.conquestSection.title[
                                language
                              ]
                            }
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="number"
                            name="conquest"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="conquest"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.massif[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="number"
                            name="massif"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="massif"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.web[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="web"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={commonDropdown}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  commonDropdown.find(
                                    (option) => option.value === field.value
                                  ) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="web"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.banner[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="banner"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={commonDropdown}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  commonDropdown.find(
                                    (option) => option.value === field.value
                                  ) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="banner"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.newsletter[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="news_letter"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={commonDropdown}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  commonDropdown.find(
                                    (option) => option.value === field.value
                                  ) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="news_letter"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.facebook[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="facebook"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={commonDropdown}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  commonDropdown.find(
                                    (option) => option.value === field.value
                                  ) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="facebook"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.adwords[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="ad_words"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={commonDropdown}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  commonDropdown.find(
                                    (option) => option.value === field.value
                                  ) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="ad_words"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {"SMS"}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="sms"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                aria-labelledby="dealership-name-quick"
                                // isClearable
                                options={commonDropdown}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  commonDropdown.find(
                                    (option) => option.value === field.value
                                  ) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"120px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="sms"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>

                    <h3 className={`mt-4 ${isDarkMode ? "darkthead" : ""}`}>
                      {translations.eventHeadings.accounting[language]}
                    </h3>
                    <div className="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.amount[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="number"
                            name="amount"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="amount"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.invoice[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="text"
                            name="invoice"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="invoice"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              pageTranslations.formFields.amountAnimator[
                                language
                              ]
                            }
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="text"
                            name="amount_animator"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="amount_animator"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              pageTranslations.formFields.invoiceAnimator[
                                language
                              ]
                            }
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="text"
                            name="invoice_animator"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="invoice_animator"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              pageTranslations.formFields.commissionPaid[
                                language
                              ]
                            }
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="text"
                            name="commission_paid"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="commission_paid"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {pageTranslations.formFields.instructions[language]}
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            type="text"
                            name="instructions"
                            class="form-control"
                            id="event-comm"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          ></Field>
                          <ErrorMessage
                            name="instructions"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>

                    {editMode && userData &&
                                  userData.roles[0].permissions.some(
                                    (item) => item.name === "Parent Form"
                                  ) &&  (
                      <div
                        style={{ marginBottom: "10px !important" }}
                        class="row"
                      >
                        <div style={{ margin: "10px 0" }} class="" id="">
                          <div class="card card-body docsection">
                            <h3 className={`${isDarkMode ? "darkthead" : ""}`}>
                              {translations.topAndsidebar.forms[language]}
                            </h3>
                            <table class="table table-hover text-center align-middle table-sm">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className={`${
                                      isDarkMode ? "darkthead" : ""
                                    }`}
                                  >
                                    #
                                  </th>
                                  <th
                                    scope="col"
                                    className={`${
                                      isDarkMode ? "darkthead" : ""
                                    }`}
                                  >
                                    {" "}
                                    {
                                      pageTranslations.formFields
                                        .documentSection.createdOn[language]
                                    }{" "}
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody id="clientEntryTable" class="">
                                {parentForms &&
                                  parentForms.length > 0 &&
                                  parentForms.map((form) => (
                                    <tr class="result-box">
                                      <td class="entryID" scope="row">
                                        {form.id}
                                      </td>
                                      <td class="submitedON">
                                        {form?.created_at?.split("T")[0]}
                                      </td>
                                      <td>
                                        <button
                                          type="button"
                                          class="admin-btn btn-primary edit-client-form-button"
                                          data-customer-id="29"
                                          data-main-id="920"
                                          onClick={() => {
                                            setDisplayComponent("edit-form");
                                            window.history.pushState(
                                              {
                                                id: "gallery",
                                                randomData:
                                                  window.Math.random(),
                                              },
                                              "title",
                                              "/event-portal?page=edit-form&event_id=" +
                                                eventId +
                                                "&form_id=" +
                                                form.id
                                            );
                                          }}
                                        >
                                          <i class="bi bi-send"></i>{" "}
                                          {
                                            translations.commonWords.view[
                                              language
                                            ]
                                          }{" "}
                                        </button>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {
                    userData && userData.roles[0].permissions.some(
                      (item) =>
                        item.name === "Private Event Show"
                    ) &&

                  <div class="footersec">
                    <div class="row">
                      <div class="col-sm" style={{ padding: 7 }}>
                        <button
                          type="submit"
                          class="client-btn btn-success mx-2"
                          onClick={() => setSubmitPressed(true)}
                          id="add-customer-button"
                        >
                          <i class="bi bi-save"></i>{" "}
                          {translations["commonFields"].submitBtn[language]}
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger"
                          onClick={
                            () =>
                              // window.history.pushState({ prevState: 'prev' }, 'title', '/event-portal?page=events')
                              window.history.back()
                            // setDisplayComponent('events')
                          }
                          id="add-customer-button"
                        >
                          <i class="bi bi-save"></i>{" "}
                          {translations["commonFields"].returnBtn[language]}
                        </button>
                        {editMode && (
                          <button
                            type="button"
                            className="admin-btn btn btn-primary mx-2"
                            // disabled={loading}
                            onClick={() => {
                              // createEvent();

                              if (eventData && eventData.copy === "0")
                                checkParent(eventId);
                              else {
                                setDisplayComponent("create-event");

                                window.history.pushState(
                                  {
                                    id: "gallery",
                                    randomData: window.Math.random(),
                                  },
                                  "title",
                                  "/event-portal?page=create-event&parent_id=" +
                                    eventId +
                                    "&event-navigate=true"
                                );
                              }
                            }}
                          >
                            <i class="bi bi-file-earmark-text"></i>{" "}
                            {translations["commonFields"].createBtn[language]}
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  }
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreatePrivateEvent;
