import React, { useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { useLanguage } from "../../../languageContext";
import translations from "../../../translation.json";
import { toast } from "react-toastify";
import { ApiUrl } from "../../../ApiUrl";

const EditAppointmenModal = ({
  isDarkMode,
  appointmentData,
  seAppointmentData,
  eventId,
  setIsLoading,
  appointments,
  setAppointments,
}) => {
  const token = localStorage.getItem("token");
  const { language } = useLanguage();

  var button = document.getElementById("closequickProspect");

  const initialData = {
    dialer_id:
      appointmentData && appointmentData ? appointmentData.dialer_id : "",
    activix_id:
      appointmentData && appointmentData ? appointmentData.activix_id : "",
    private_event_id: eventId,
    prospect_type:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.prospect_type
        : "",
    first_name:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.first_name
        : "",
    last_name:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.last_name
        : "",
    phone:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.phone
        : "",
    make:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.make
        : "",
    model:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.model
        : "",
    year:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.year
        : "",
    appointment_date:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.appointment_date
        : "",
    appointment_time:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.appointment_time
        : "",
    appointment_comment:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.appointment_comment
        : "",
    appointment_status:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.appointment_status
        : "",
    appointment_status_notes:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.appointment_status_notes
        : "",
    confirmation_status:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.confirmation_status
        : "",
    confirmation_notes:
      appointmentData && appointmentData
        ? appointmentData.appointments[0]?.confirmation_notes
        : "",
    prospect_source:
      appointmentData && appointmentData ? appointmentData.prospect_source : "",
    greetings:
      appointmentData && appointmentData ? appointmentData.greetings : "",
    mobile_phone:
      appointmentData && appointmentData ? appointmentData.mobile_phone : "",
    office_phone: appointmentData ? appointmentData.office_phone : "",
    address: appointmentData ? appointmentData.address : "",
    city: appointmentData ? appointmentData.city : "",
    province: appointmentData ? appointmentData.province : "",
    postal_code: appointmentData ? appointmentData.postal_code : "",
    email: appointmentData ? appointmentData.email : "",
    purchase_date: appointmentData ? appointmentData.purchase_date : "",
    last_service_date: appointmentData ? appointmentData.last_service_date : "",
    km: appointmentData ? appointmentData.km : "",
    vin: appointmentData ? appointmentData.vin : "",
    call_status: appointmentData ? appointmentData.call_status : "",
    confirmation_status_entry_date: appointmentData
      ? appointmentData.confirmation_status_entry_date
      : "",
    visit_status: appointmentData ? appointmentData.visit_status : "",
    visit_status_entry_date: appointmentData
      ? appointmentData.visit_status_entry_date
      : "",
    visit_notes: appointmentData ? appointmentData.visit_notes : "",
    no_show_status: appointmentData ? appointmentData.no_show_status : "",
    no_show_status_entry_date: appointmentData
      ? appointmentData.no_show_status_entry_date
      : "",
    no_show_notes: appointmentData ? appointmentData.no_show_notes : "",
  };

  const handleSubmit = async (values, { resetForm }, error) => {
    setIsLoading(true);
    try {
      const url = `${ApiUrl}/api/user/prospectives${`/${appointmentData.id}`}`;
      const bodyData = values;
      const method = "PUT";
      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      // console.log("res", res)
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        const newData =
          appointments &&
          appointments.map((data) =>
            data.id == appointmentData.id
              ? {
                  ...data,
                  email: values.email,
                  appointments: [
                    {
                      ...data.appointments[0],
                      first_name: values.first_name,
                      last_name: values.last_name,
                      appointment_date: values.appointment_date,
                      appointment_time: values.appointment_time,
                      phone: values.phone,
                    },
                    ...data.appointments.slice(1),
                  ],
                }
              : data
          );
        setAppointments(newData);
        toast.success(data.message);
        button.click();
        // navigate("/event-portal");
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  return (
    <div id="editAppointment" className="modal fade" tabindex="-1">
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <Formik
            initialValues={initialData}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
            id="creationsuccess"
            enableReinitialize={true}
          >
            {({ errors, values, setValues, resetForm, isSubmitting }) => (
              <Form>
                <div
                  class="modal-header"
                  style={{
                    borderBottom: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <h3 style={{ color: "#000000" }}>
                    {translations.appointments.appointmentDetails[language]}
                  </h3>
                  <button
                    type="button"
                    class="btn-close"
                    // id="bulkUploadClose"
                    data-bs-dismiss="modal"
                    style={{
                      filter: isDarkMode ? "invert(1)" : "none", // Invert the color in dark mode
                      color: isDarkMode
                        ? "#ffffff"
                        : "rgb(255, 255, 255) !important",
                    }}
                    id="closeModalBtn"
                  ></button>
                </div>
                <div
                  className="modal-body"
                  style={{ minHeight: "200px)", overflowY: "scroll" }}
                >
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.firstName[language]}
                        </label>
                        <Field
                          type="text"
                          name="first_name"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />

                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.lastName[language]}
                        </label>
                        <Field
                          type="text"
                          name="last_name"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.clients.table.appointmentDate[language]}
                        </label>
                        <Field
                          type="date"
                          name="appointment_date"
                          className={`form-control ${
                            isDarkMode ? "custom-date" : ""
                          }`}
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              appointment_date: e.target.value,
                            });
                          }}
                        />

                        <ErrorMessage
                          name="appointment_date"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.clients.table.appointmentTime[language]}
                        </label>
                        <Field
                          type="time"
                          name="appointment_time"
                          className={`form-control ${
                            isDarkMode ? "custom-date" : ""
                          }`}
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                          onChange={(e) => {
                            setValues({
                              ...values,
                              appointment_time: e.target.value,
                            });
                          }}
                        />
                        <ErrorMessage
                          name="appointment_time"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div class="col">
                      <div class="mb-3">
                        <label for="annual-sales-count" class="form-label">
                          {translations.commonFields.email[language]}
                        </label>
                        <Field
                          type="email"
                          name="email"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>{" "}
                    <div className="col">
                      <div className="mb-3">
                        <label for="annual-sales-count" className="form-label">
                          {translations.commonFields.phone[language]}
                        </label>
                        <Field
                          type="text"
                          name="phone"
                          className="form-control"
                          style={{
                            backgroundColor: isDarkMode ? "transparent" : "",
                            color: isDarkMode ? "#ffffff" : "#000000",
                            border: isDarkMode
                              ? "1px solid #495057"
                              : "1px solid #dee2e6",
                          }}
                        />

                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="error text-danger"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="modal-footer"
                  id="modalfooter"
                  style={{
                    borderTop: isDarkMode
                      ? "1px solid #495057"
                      : "1px solid #dee2e6",
                  }}
                >
                  <button
                    type="submit"
                    class="admin-btn btn btn-primary"
                    disabled={isSubmitting}
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                  >
                    {translations.commonFields.submitBtn[language]}
                  </button>
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-bs-dismiss="modal"
                    id="closequickProspect"
                    // data-bs-toggle={quickAdd ? "modal" : ""}
                    // data-bs-target={quickAdd ? "#modalQuickAdd" : ""}
                    // onClick={() => {
                    //   resetForm();
                    //   setValues({
                    //     csv_file: "",
                    //     private_event_id: "",
                    //   });
                    //   setSelected("");
                    // }}
                  >
                    {translations["commonFields"].closeBtn[language]}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default EditAppointmenModal;
