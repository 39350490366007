import React, { useEffect, useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import AppointmentsSection from "./appointmentsSection";
import SingleAppointmentsSection from "./singleAppointmentsSection.";
import FollowUp from "./followup";

const EvenView = ({ isDarkMode, setDisplayComponent, setPrevDisplay }) => {
  const { language } = useLanguage();
  const [eventId, setEventId] = useState(null);

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const lastParameterValue = currentUrl.substring(secondEqualsIndex + 1);
        setEventId(lastParameterValue);
      }
    }
  });
  return (
    <div>
      <div className="container maincont">
        <div id="app" className="appcountainer2">
          <div className="row align-items-center justify-content-between">
            <div className="col-4 d-flex gap-3 align-items-center">
              <div>
                <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                  {translations.eventView.title[language]}
                </h2>
              </div>
            </div>
            <div className="col-8 gap-1 d-flex align-items-center justify-content-end">
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                  onClick={() => {
                    setDisplayComponent("events");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      "/event-portal?page=events"
                    );
                  }}
                >
                  <i className="bi bi-speedometer"></i>{" "}
                  {translations.eventView.buttons.dashboard[language]}
                </button>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary"
                  onClick={() => {
                    setDisplayComponent("appointments");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      `/event-portal?page=appointments&eventId=${eventId}`
                    );
                  }}
                >
                  <i className="bi bi-calendar2-range"></i>{" "}
                  {translations.eventView.buttons.calender[language]}
                </button>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                  onClick={() => {
                    setDisplayComponent("clients");

                    window.history.pushState(
                      {
                        id: "gallery",
                        randomData: window.Math.random(),
                      },
                      "title",
                      `/event-portal?page=clients&eventId=${eventId}`
                    );
                  }}
                >
                  <i className="bi bi-people"></i>{" "}
                  {translations.forms.formFields.clients[language]}
                </button>
              </div>
              <div
                className="btn-group"
                role="group"
                aria-label="Basic example"
              >
                <button
                  type="button"
                  className="btn admin-btn btn-primary"
                  // data-bs-toggle="modal"
                  // data-bs-target="#modalQuickAdd"
                  // fdprocessedid="2rud2c"
                >
                  <i className="bi bi-file-earmark-bar-graph"></i>{" "}
                  {translations.viewEventReport.title[language]}
                </button>
              </div>
            </div>
          </div>
          <div>
            <AppointmentsSection isDarkMode={isDarkMode} />
          </div>
          <div className="row">
            <div className="col-6">
              <SingleAppointmentsSection isDarkMode={isDarkMode} />
            </div>
            <div className="col-6">
              <FollowUp isDarkMode={isDarkMode} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvenView;
