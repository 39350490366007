import React, { useEffect, useState } from "react";
import { useLanguage } from "../languageContext";
import translations from "../translation.json";
import * as Yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import { ApiUrl } from "../ApiUrl";
import { toast } from "react-toastify";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
const CreateProspective = ({
  isDarkMode,
  setDisplayComponent,
  setPrevDisplay,
  editMode = false,
  setIsLoading,
}) => {
  const { language } = useLanguage();
  const token = localStorage.getItem("token");
  const [eventId, setEventId] = useState(null);
  const [prospectId, setProspectId] = useState(null);
  const [prospectData, setProspectData] = useState(null);

  useEffect(() => {
    // Get the current URL
    var currentUrl = window.location.href;
    // Check if '=' is found and extract the text after it
    const equalsIndex = currentUrl.indexOf("=");
    if (equalsIndex !== -1) {
      const secondEqualsIndex = currentUrl.indexOf("=", equalsIndex + 1);

      if (secondEqualsIndex !== -1) {
        const ampersandIndex = currentUrl.indexOf("&", secondEqualsIndex + 1); //=41&p=27

        if (ampersandIndex !== -1) {
          const editClientIdValue = currentUrl.substring(
            secondEqualsIndex + 1,
            ampersandIndex
          ); //=41
          setEventId(editClientIdValue);

          const thirdEqualsIndex = currentUrl.indexOf("=", ampersandIndex + 1); // =27

          if (thirdEqualsIndex !== -1) {
            const eventIDValue = currentUrl.substring(thirdEqualsIndex + 1);
            setProspectId(eventIDValue);
          }
        } else {
          // If no '&' found after the second '=', extract until the end of the URL
          const editClientIdValue = currentUrl.substring(secondEqualsIndex + 1);
          setEventId(editClientIdValue);
        }
      }
    }
  });

  useEffect(() => {
    const getProspectData = async () => {
      setIsLoading(true);
      try {
        const res = await fetch(
          ApiUrl + "/api/user/prospectives/" + prospectId,

          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await res.json();
        if (data.status === "success") {
          setProspectData(data.prospective);
        } else {
          toast.error(data.message);
        }
        setIsLoading(false);
      } catch (err) {
        setIsLoading(false);
        toast.error("Something went wrong");
      } finally {
      }
    };
    if (eventId && prospectId) {
      getProspectData(eventId);
    }
  }, [eventId]);

  const initialData = {
    activix_id: editMode && prospectData ? prospectData.activix_id : "",
    private_event_id:
      editMode && prospectData ? prospectData.private_event_id : eventId,
    prospect_type:
      editMode && prospectData
        ? prospectData.appointments[0]?.prospect_type
        : "",
    first_name:
      editMode && prospectData ? prospectData.appointments[0]?.first_name : "",
    last_name:
      editMode && prospectData ? prospectData.appointments[0]?.last_name : "",
    phone: editMode && prospectData ? prospectData.appointments[0]?.phone : "",
    make: editMode && prospectData ? prospectData.appointments[0]?.make : "",
    model: editMode && prospectData ? prospectData.appointments[0]?.model : "",
    year: editMode && prospectData ? prospectData.appointments[0]?.year : "",
    appointment_date:
      editMode && prospectData
        ? prospectData.appointments[0]?.appointment_date
        : "",
    appointment_time:
      editMode && prospectData
        ? prospectData.appointments[0]?.appointment_time
        : "",
    appointment_comment:
      editMode && prospectData
        ? prospectData.appointments[0]?.appointment_comment
        : "",
    appointment_status:
      editMode && prospectData
        ? prospectData.appointments[0]?.appointment_status
        : "",
    appointment_status_notes:
      editMode && prospectData
        ? prospectData.appointments[0]?.appointment_status_notes
        : "",
    confirmation_status:
      editMode && prospectData
        ? prospectData.appointments[0]?.confirmation_status
        : "",
    confirmation_notes:
      editMode && prospectData
        ? prospectData.appointments[0]?.confirmation_notes
        : "",
    prospect_source:
      editMode && prospectData ? prospectData.prospect_source : "",
    greetings: editMode && prospectData ? prospectData.greetings : "",
    mobile_phone: editMode && prospectData ? prospectData.mobile_phone : "",
    office_phone: editMode && prospectData ? prospectData.office_phone : "",
    address: editMode && prospectData ? prospectData.address : "",
    city: editMode && prospectData ? prospectData.city : "",
    province: editMode && prospectData ? prospectData.province : "",
    postal_code: editMode && prospectData ? prospectData.postal_code : "",
    email: editMode && prospectData ? prospectData.email : "",
    purchase_date: editMode && prospectData ? prospectData.purchase_date : "",
    last_service_date:
      editMode && prospectData ? prospectData.last_service_date : "",
    km: editMode && prospectData ? prospectData.km : "",
    vin: editMode && prospectData ? prospectData.vin : "",
    call_status: editMode && prospectData ? prospectData.call_status : "",
    confirmation_status_entry_date:
      editMode && prospectData
        ? prospectData.confirmation_status_entry_date
        : "",
    visit_status: editMode && prospectData ? prospectData.visit_status : "",
    visit_status_entry_date:
      editMode && prospectData ? prospectData.visit_status_entry_date : "",
    visit_notes: editMode && prospectData ? prospectData.visit_notes : "",
    no_show_status: editMode && prospectData ? prospectData.no_show_status : "",
    no_show_status_entry_date:
      editMode && prospectData ? prospectData.no_show_status_entry_date : "",
    no_show_notes: editMode && prospectData ? prospectData.no_show_notes : "",
  };

  const prospectiveSourceOptions = [
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt1[language],
      value: translations.createProspective.prospectiveSourceOptions.opt1["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt2[language],
      value: translations.createProspective.prospectiveSourceOptions.opt2["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt3[language],
      value: translations.createProspective.prospectiveSourceOptions.opt3["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt4[language],
      value: translations.createProspective.prospectiveSourceOptions.opt4["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt5[language],
      value: translations.createProspective.prospectiveSourceOptions.opt5["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt6[language],
      value: translations.createProspective.prospectiveSourceOptions.opt6["en"],
    },
    {
      label:
        translations.createProspective.prospectiveSourceOptions.opt7[language],
      value: translations.createProspective.prospectiveSourceOptions.opt7["en"],
    },
  ];
  const visitStatusOptions = [
    {
      label: translations.createProspective.visitStatusOptions.opt1[language],
      value: translations.createProspective.visitStatusOptions.opt1["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt2[language],
      value: translations.createProspective.visitStatusOptions.opt2["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt3[language],
      value: translations.createProspective.visitStatusOptions.opt3["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt4[language],
      value: translations.createProspective.visitStatusOptions.opt4["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt5[language],
      value: translations.createProspective.visitStatusOptions.opt5["en"],
    },
    {
      label: translations.createProspective.visitStatusOptions.opt6[language],
      value: translations.createProspective.visitStatusOptions.opt6["en"],
    },
  ];

  const handleSubmit = async (values, { resetForm }, error) => {
    setIsLoading(true);
    try {
      const url = `${ApiUrl}/api/user/prospectives${
        editMode ? `/${prospectId}` : ""
      }`;
      const bodyData = editMode ? { ...values, dialer_id: prospectData.dialer_id } : values;
      const method = editMode ? "PUT" : "POST";
      const res = await fetch(url, {
        method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(bodyData),
      });
      // console.log("res", res)
      const data = await res.json();
      // console.log("data", data)
      if (data.status === "success") {
        toast.success(data.message);
        // navigate("/event-portal");
      } else {
        toast.error(data.message);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong!");
    }
  };

  return (
    <div>
      <div className="container maincont">
        <div id="app" className="appcountainer2">
          <div className="row align-items-center justify-content-between">
            <div className="d-flex justify-content-between gap-3 align-items-center">
              <div>
                <h2 id="h1" className={`${isDarkMode ? "darkthead" : ""}`}>
                  {window.location.href.includes("edit-appointment")
                    ? translations.appointments.editAppointment[language]
                    : editMode
                    ? translations.createProspective.editProspect[language]
                    : translations.createProspective.title[language]}
                </h2>
              </div>
              <div className="">
                <button
                  className="w-100 admin-btn btn-lg btn-primary d-flex align-items-center gap-1"
                  type="button"
                  onClick={() => window.history.back()}
                >
                  <i class="bi bi-arrow-return-left"></i>
                  {window.location.href.includes("edit-appointment")
                    ? translations.commonFields.backToAppointments[language]
                    : translations.commonFields.backToProspects[language]}
                </button>
              </div>
            </div>
            {false && (
              <div className="col-8 gap-1 d-flex align-items-center justify-content-end">
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                    //   onClick={() => {
                    //     setDisplayComponent("events");

                    //     window.history.pushState(
                    //       {
                    //         id: "gallery",
                    //         randomData: window.Math.random(),
                    //       },
                    //       "title",
                    //       "/event-portal?page=events"
                    //     );
                    //   }}
                  >
                    {/* <i className="bi bi-speedometer"></i>{" "} */}
                    {translations.commonFields.upload[language]}
                  </button>
                </div>
                <div
                  className="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    type="button"
                    className="btn admin-btn btn-primary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#modalQuickAdd"
                    // fdprocessedid="2rud2c"
                  >
                    {/* <i className="bi bi-calendar2-range"></i>{" "} */}
                    {translations.commonFields.bulkUpload[language]}
                  </button>
                </div>
              </div>
            )}
          </div>
          <Formik
            initialValues={initialData}
            // validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize={true}
          >
            {({ errors, values, setValues, setFieldValue, isSubmitting }) => (
              <Form>
                <div>
                  <div
                    className="p-2"
                    // style={{ height: "calc(100vh - 240px)" }}
                  >
                    <h3 className={`mt-2 ${isDarkMode ? "darkthead" : ""}`}>
                      {translations.eventHeadings.general[language]}
                    </h3>
                    <div className="row">
                      <div className="col">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              for="annual-sales-count"
                              className="form-label"
                            >
                              Activix ID
                            </label>
                            <Field
                              type="text"
                              name="activix_id"
                              className="form-control"
                              style={{
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />

                            <ErrorMessage
                              name="activix_id"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              for="annual-sales-count"
                              className="form-label"
                            >
                              {
                                translations.clients.table.prospectiveType[
                                  language
                                ]
                              }
                            </label>
                            <Field
                              type="text"
                              name="prospect_type"
                              className="form-control"
                              style={{
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />

                            <ErrorMessage
                              name="prospect_type"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>

                      <div className="col">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.commonFields.firstName[language]}
                          </label>
                          <Field
                            type="text"
                            name="first_name"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />

                          <ErrorMessage
                            name="first_name"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.commonFields.lastName[language]}
                          </label>
                          <Field
                            type="text"
                            name="last_name"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="last_name"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              for="annual-sales-count"
                              className="form-label"
                            >
                              {translations.commonFields.phone[language]}
                            </label>
                            <Field
                              type="text"
                              name="phone"
                              className="form-control"
                              style={{
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />

                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.createProspective.make[language]}
                          </label>
                          <Field
                            type="text"
                            name="make"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />

                          <ErrorMessage
                            name="make"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.createProspective.model[language]}
                          </label>
                          <Field
                            type="text"
                            name="model"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="model"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              for="annual-sales-count"
                              className="form-label"
                            >
                              {translations.usersPage.createUser.year[language]}
                            </label>
                            <Field
                              type="number"
                              name="year"
                              className="form-control"
                              style={{
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />

                            <ErrorMessage
                              name="year"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.clients.table.appointmentDate[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="date"
                            name="appointment_date"
                            className={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                appointment_date: e.target.value,
                              });
                            }}
                          />

                          <ErrorMessage
                            name="appointment_date"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.clients.table.appointmentTime[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="time"
                            name="appointment_time"
                            className={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                appointment_time: e.target.value,
                              });
                            }}
                          />
                          <ErrorMessage
                            name="appointment_time"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              for="annual-sales-count"
                              className="form-label"
                            >
                              {
                                translations.createProspective
                                  .appointmentComment[language]
                              }
                            </label>
                            <Field
                              type="text"
                              name="appointment_comment"
                              className="form-control"
                              style={{
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />

                            <ErrorMessage
                              name="appointment_comment"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.clients.table.appointmentStatus[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="text"
                            name="appointment_status"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />

                          <ErrorMessage
                            name="appointment_status"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.createProspective
                                .appointmentStatusNotes[language]
                            }
                          </label>
                          <Field
                            type="text"
                            name="appointment_status_notes"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="appointment_status_notes"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="col">
                          <div className="mb-3">
                            <label
                              for="annual-sales-count"
                              className="form-label"
                            >
                              {
                                translations.createProspective
                                  .confirmationStatus[language]
                              }
                            </label>
                            <Field
                              type="text"
                              name="confirmation_status"
                              className="form-control"
                              style={{
                                backgroundColor: isDarkMode
                                  ? "transparent"
                                  : "",
                                color: isDarkMode ? "#ffffff" : "#000000",
                                border: isDarkMode
                                  ? "1px solid #495057"
                                  : "1px solid #dee2e6",
                              }}
                            />

                            <ErrorMessage
                              name="confirmation_status"
                              component="div"
                              className="error text-danger"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.createProspective.confirmationNotes[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="text"
                            name="confirmation_notes"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />

                          <ErrorMessage
                            name="confirmation_notes"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {translations.commonFields.mobileNumber[language]}
                          </label>
                          <Field
                            type="text"
                            name="mobile_phone"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />

                          <ErrorMessage
                            name="mobile_phone"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              translations.createProspective.prospectiveSource[
                                language
                              ]
                            }
                          </label>
                          {/* <Field type="text" name="digital" class="form-control" id="annual-sales-count" fdprocessedid="g00vec" /> */}
                          <Field
                            name="prospect_source"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                // isClearable
                                options={prospectiveSourceOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (prospectiveSourceOptions &&
                                    prospectiveSourceOptions.length > 0 &&
                                    prospectiveSourceOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"85px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="prospect_source"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {translations.createProspective.greetings[language]}
                          </label>
                          <Field
                            type="text"
                            name="greetings"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="greetings"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>{" "}
                    </div>
                    <div className="row">
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              translations.createProspective.officeNumber[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="text"
                            name="office_phone"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="office_phone"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {translations.commonFields.address[language]}
                          </label>
                          <Field
                            type="text"
                            name="address"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="address"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {translations.commonFields.city[language]}
                          </label>
                          <Field
                            type="text"
                            name="city"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="city"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>{" "}
                    </div>
                    <div className="row">
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {translations.commonFields.province[language]}
                          </label>
                          <Field
                            type="text"
                            name="province"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="province"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>{" "}
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              translations.forms.formFields.conquestSection
                                .postalCode[language]
                            }
                          </label>
                          <Field
                            type="text"
                            name="postal_code"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="postal_code"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {translations.commonFields.email[language]}
                          </label>
                          <Field
                            type="email"
                            name="email"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="email"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>{" "}
                    </div>
                    <div className="row">
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.createProspective.purchaseDate[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="date"
                            name="purchase_date"
                            className={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                purchase_date: e.target.value,
                              });
                            }}
                          />

                          <ErrorMessage
                            name="purchase_date"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.createProspective.lastServiceDate[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="date"
                            name="last_service_date"
                            className={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                last_service_date: e.target.value,
                              });
                            }}
                          />

                          <ErrorMessage
                            name="last_service_date"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            KM
                          </label>
                          <Field
                            type="text"
                            name="km"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="km"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {translations.createProspective.VIN[language]}
                          </label>
                          <Field
                            type="text"
                            name="vin"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="vin"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>{" "}
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {translations.clients.table.callStatus[language]}
                          </label>
                          <Field
                            type="text"
                            name="call_status"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="call_status"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.createProspective
                                .confirmationStatusDate[language]
                            }
                          </label>
                          <Field
                            type="date"
                            name="confirmation_status_entry_date"
                            className={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                confirmation_status_entry_date: e.target.value,
                              });
                            }}
                          />

                          <ErrorMessage
                            name="confirmation_status_entry_date"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              translations.createProspective.visitStatus[
                                language
                              ]
                            }
                          </label>
                          <Field
                            name="visit_status"
                            render={({ field, form }) => (
                              <Select
                                className="selectpicker"
                                // isClearable
                                options={visitStatusOptions}
                                placeholder={
                                  translations["dropdown"].select[language]
                                }
                                isSearchable
                                value={
                                  (visitStatusOptions &&
                                    visitStatusOptions.length > 0 &&
                                    visitStatusOptions.find(
                                      (option) => option.value === field.value
                                    )) || {
                                    value: "",
                                    label:
                                      translations["dropdown"].select[language],
                                  }
                                }
                                onChange={(selectedOption) =>
                                  form.setFieldValue(
                                    field.name,
                                    selectedOption.value
                                  )
                                }
                                maxMenuHeight={"85px"}
                                styles={{
                                  input: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black", // Color of the text when typing
                                  }),
                                  control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: isDarkMode
                                      ? "transparent"
                                      : "white",
                                    border: state.isFocused
                                      ? "1px solid #80bdff"
                                      : isDarkMode
                                      ? "1px solid #495057"
                                      : "1px solid #dee2e6", // Adjust the border color for focus
                                    boxShadow: state.isFocused
                                      ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)"
                                      : "none", // Bootstrap's box shadow for focus
                                    borderRadius: ".45rem",
                                  }),
                                  valueContainer: (provided) => ({
                                    ...provided,
                                    padding: "0.18rem 0.55rem", // Bootstrap's padding for the value container
                                  }),
                                  indicatorSeparator: (provided) => ({
                                    ...provided,
                                    display: "none", // Hide the indicator separator
                                  }),
                                  dropdownIndicator: (provided) => ({
                                    ...provided,
                                    padding: "0.375rem 0.75rem", // Bootstrap's padding for the dropdown indicator
                                    color: isDarkMode ? "#ffffff" : "#868686",
                                  }),
                                  menu: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    margin: "0", // Remove default margin
                                  }),
                                  placeholder: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "#333333", // Specify your desired color for the placeholder
                                    // Additional styles for placeholder
                                  }),
                                  singleValue: (provided) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                  }),
                                  option: (provided, state) => ({
                                    ...provided,
                                    color: isDarkMode ? "#ffffff" : "black",
                                    backgroundColor: state.isHovered
                                      ? "#80bdff"
                                      : isDarkMode
                                      ? "#1f2023"
                                      : "white",
                                    ":hover": {
                                      backgroundColor: isDarkMode
                                        ? "#34DBB4"
                                        : "#34DBB4",
                                    },
                                  }),
                                }}
                              />
                            )}
                          />
                          <ErrorMessage
                            name="visit_status"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>{" "}
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.createProspective.visitStatusDate[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="date"
                            name="visit_status_entry_date"
                            className={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                visit_status_entry_date: e.target.value,
                              });
                            }}
                          />

                          <ErrorMessage
                            name="visit_status_entry_date"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              translations.createProspective.visitNotes[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="text"
                            name="visit_notes"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="visit_notes"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              translations.createProspective.noShowSatus[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="text"
                            name="no_show_status"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="no_show_status"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="mb-3">
                          <label
                            for="annual-sales-count"
                            className="form-label"
                          >
                            {
                              translations.createProspective
                                .no_show_status_entry_date[language]
                            }
                          </label>
                          <Field
                            type="date"
                            name="no_show_status_entry_date"
                            className={`form-control ${
                              isDarkMode ? "custom-date" : ""
                            }`}
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                            onChange={(e) => {
                              setValues({
                                ...values,
                                no_show_status_entry_date: e.target.value,
                              });
                            }}
                          />

                          <ErrorMessage
                            name="no_show_status_entry_date"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="mb-3">
                          <label for="annual-sales-count" class="form-label">
                            {
                              translations.createProspective.noShowNotes[
                                language
                              ]
                            }
                          </label>
                          <Field
                            type="text"
                            name="no_show_notes"
                            className="form-control"
                            style={{
                              backgroundColor: isDarkMode ? "transparent" : "",
                              color: isDarkMode ? "#ffffff" : "#000000",
                              border: isDarkMode
                                ? "1px solid #495057"
                                : "1px solid #dee2e6",
                            }}
                          />
                          <ErrorMessage
                            name="no_show_notes"
                            component="div"
                            className="error text-danger"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="footersec">
                    <div class="row">
                      <div class="col-sm" style={{ padding: 7 }}>
                        <button
                          type="submit"
                          className="client-btn btn-success mx-2"
                          //   onClick={() => setSubmitPressed(true)}
                          id="add-customer-button"
                          disabled={isSubmitting}
                        >
                          <i class="bi bi-save"></i>{" "}
                          {translations["commonFields"].submitBtn[language]}
                        </button>
                        <button
                          type="button"
                          class="btn btn-danger"
                          onClick={
                            () =>
                              // window.history.pushState({ prevState: 'prev' }, 'title', '/event-portal?page=events')
                              window.history.back()
                            // setDisplayComponent('events')
                          }
                          id="add-customer-button"
                        >
                          <i class="bi bi-save"></i>{" "}
                          {translations["commonFields"].returnBtn[language]}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default CreateProspective;
